import { Component, Inject, AfterViewInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { UtilService } from './services/util.service';
import { apiError } from './interface/interface';
import { Store } from '@ngrx/store';
import { SET_PAGE_LINK, SET_LANG_FORMAT } from './store/action';
import { AuthenService } from './services/authen.service';
import { SettingService } from './services/setting.service';
// import { FeatureService } from './services/feature.service';
import { environment } from 'src/environments/environment';
import { Etc } from '@cms/util/etc';
import * as jwt_decode from "jwt-decode";
import * as moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router';
import { pathLangPipe } from './pipes/path-lang.pipe';
import { NgCoreService } from './services/ng-core.service';
import { DOCUMENT } from '@angular/common';
// import * as  from 'web-vitals';
const WebVitals = require('web-vitals');

declare var fbq:any,ga:any,gtag: any, window: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{

    initPage: boolean = false

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _cookie: CookieService,
        private _util: UtilService,
        private _authen: AuthenService,
        private store: Store<{ app_store: any }>,
        private _setting: SettingService,
        private router: Router,
        private ngCoreService: NgCoreService,
        // private Service_Feature: FeatureService,
        private _etc: Etc,
        private path_lang: pathLangPipe,
        private _meta: Meta,
        private activatedRoute: ActivatedRoute
    ) {
        console.log('App Start');

        //  denied pdpa
        window.dataLayer = window.dataLayer || [];
        window.gtag = function(){
          window.dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });


        this.loadPageLink()
        this.fbConversion()
        try{
          window['ket_mode'] = localStorage.getItem('ket_mode') || window['ket_mode']
          if(window['ket_mode'] == 'design'){
            // load 1
            var script1 = document.createElement('script');
            script1.onload =  () => {

              // load 2
              var script2 = document.createElement('script');
              script2.onload =  () => {

              };
              script2.src = '/assets/persistence/persistence.store.sql.js';
              document.body.appendChild(script2);


              // load 3
              var script3 = document.createElement('script');
              script3.onload =  () => {
              };
              script3.src = '/assets/persistence/persistence.store.websql.js';
              document.body.appendChild(script3);

                          // load 4
            var script4 = document.createElement('script');
            script4.onload =  () => {
            };
            script4.src = '/assets/persistence/persistence.store.memory.js';
            document.body.appendChild(script4);

            };
            script1.src = '/assets/persistence/persistence.js';
            document.body.appendChild(script1);
          }
        }catch(err){}
        // AOS.init();
        // this.Service_Feature._init();

        if(!environment.beta){
          console.log = ()=>{
            return 0
          }

          console.table = ()=>{
            return 0
          }

        }

        try{
          if(!window['seo']){
            var { access_token } = JSON.parse(localStorage.getItem('client_access_token'))
            var user = jwt_decode(access_token)
            if(user.user_role == 'guest'){
              localStorage.removeItem('client_access_token');
            }
          }
        }catch(err){}

        this.resetCookie();
        this.init();

        // this._util.setFeature({id:1,name:'awef'});
        try {
            var langs: any = JSON.parse(localStorage.getItem('langs'));
            var lang = this._etc.getCurrentLang();
            console.log("lang============ang",lang);
            if(!lang){
              lang = "lang1"
            }
            var lang_data = langs.find((x: any) => x.ref == lang);

            if (!lang_data) {
                lang_data = langs.find((x: any) => x.ref == 'lang1');
            }
            if (!lang_data) {
                lang_data = langs.find((x: any) => x.ref == 'lang2');
            }
            if (!lang_data) {
                lang_data = langs.find((x: any) => x.ref == 'lang3');
            }
            if (!lang_data) {
                lang_data = langs.find((x: any) => x.ref == 'lang4');
            }

            if (!lang_data) {
                // localStorage.setItem('lang', "lang1")
                this._etc.setCurrentLang("lang1");
            } else {
              this._etc.setCurrentLang(lang_data.ref);
                // localStorage.setItem('lang', lang_data.ref)
            }


        } catch (err) {

        }

        if (!this._authen.isTokenExpired()) {
            this._authen.setClientProfile()
        }

        this._setting.searchOption({
            pop_key: ['theme_value', 'default_no_image', 'favicon_icon']
        }).then((res: any[]) => {

          for(let obj of res){
            switch (obj.pop_key) {
              case 'theme_value':
                try {

                    var _data = JSON.parse(obj.pop_value)
                    //console.log(_data);
                    window["_theme_color"] = _data
                } catch (e) {

                    eval(`${obj.pop_value} window._theme_color = _theme_color;`)

                }
                break;
              case 'default_no_image':
                localStorage.setItem('default_no_image', obj.pop_value)
                break
              case 'favicon_icon':
                try{


                  var _uri = ''
                  if(!window["_host"]){
                    _uri = window.location.hostname
                  }else{
                    _uri = `${window["_host"]}`
                  }

                  this._document.getElementById('appFavicon').setAttribute('href', `https://${_uri}${obj.pop_value}`);

                  //$(`<link rel="icon" type="image/x-icon" href="${obj.pop_value}" />`).appendTo('head')

                }catch(err){}
                break
              default:
                break;
            }
          }

        }).catch((e: any) => {
            //console.log(e.message)

        })
    }

    fbConversion(){
      let data: any = {}
    }

    resetCookie() {
        this._cookie.remove('pages')
        this._cookie.remove('langs')
    }

    ngAfterViewInit(){
      setTimeout(() => {
        var url = window.location.href
        let urlto = this.path_lang.transform('thankyou')
        if(url.search(`${window.location.origin}/system`) == -1 && !url.includes('credit-redirect') && !url.includes('crypto-redirect') && !url.includes(urlto)){
          let lastorder = localStorage.getItem('last_order');
          if(lastorder){
            this.router.navigate([`${urlto}`]);
          }
        }
      },1500)
      if(window.location.href.includes('/system') 
      || window.location.href.includes('/orderlink/') 
      || window.location.href.includes('/order-page/')
      || window.location.href.includes('/confirmregister')
      || window.location.href.includes('/forgotpassword')
      || window.location.href.includes('/crypto-redirect')
      || window.location.href.includes('/credit-redirect')
      ){
        this.ngCoreService.zoneIdleCheck()
      }
    }


    async init(){
      /// App init
      if(!window["seo"]){
        var url = window.location.href
        if(url.search(`${window.location.origin}/system`) == -1){
          $('body').addClass('frontend');
          $('body').removeClass('backend')
          /*
          const sendToAnalytics = (metric: any) =>{
            const body = JSON.stringify(metric);
            if(navigator.sendBeacon){
              console.log('metric', metric)
              let sendBeacon = navigator.sendBeacon('/analytics', body)
              console.log('sendBeacon', sendBeacon)
            }
          }
          WebVitals.onCLS(sendToAnalytics)
          WebVitals.onFCP(sendToAnalytics)
          WebVitals.onFID(sendToAnalytics)
          WebVitals.onINP(sendToAnalytics)
          WebVitals.onLCP(sendToAnalytics)
          WebVitals.onTTFB(sendToAnalytics)
          */
        }else{
          $('body').addClass('backend');
          $('body').removeClass('frontend')
        }
      }

      try{
        if(!window['seo']){
          window['timemachine'].tick = true;
          var _time:any = await this._util.getDateTime()
         // console.log(_time)
          var date_string = moment(_time.date_time).add(5,'s').toString();
          date_string = date_string.replace(/GMT(.*)[0-9]/,"GMT+0700")
          window['timemachine'].config({
            dateString: `${date_string} (Indochina Time)`
          });
        }
        // s
      }catch(err){}

        try{
          await this.getLangFormat();
        }catch(err){}

        if(!window["seo"]){
          var url = window.location.href
          if(url.search(`${window.location.origin}/system`) == -1){
            this.loadConversionScript();
          }
        }
    }



    getLangFormat(){
        return new Promise((resolve,reject)=>{
            this._util.getAllLangFormat().then((res: any) => {
                this.store.dispatch(new SET_LANG_FORMAT(res));
                resolve(true);
            }).catch((e: apiError) => {
                reject(true);
                //console.log(e.error.message)
            })
        })

    }

    loadPageLink() {
        this._util.getPageLinkAll().then((res: any) => {
            //console.log(res)
            localStorage.setItem('SET_PAGE_LINK', JSON.stringify(res))

            this.store.dispatch(new SET_PAGE_LINK(res));
            this.initPage = true
            this._authen.setClientProfile()
        }).catch((e: apiError) => {
            //console.log(e.error.message)
        })
    }

    timeCheckPDPA = null as any
    async loadConversionScript(){
        let pop_key = [
          'track_script', 
          'google_tag_manager_head', 
          'google_tag_manager_body', 
          'google_adword_remarketing', 
          'pixel_code_id', 
          'tiktok_pixel',
          'line_pixel_head',
          'line_pixel_event',
          'facebook_domain_verify',
          'google_webmaster_tool'
        ];
        var option: any = []
        try{
          option = await this._setting.searchOption({ pop_key: pop_key });
        }catch(err){}
        let _option:any = {};
        option.forEach(obj => {
            _option[obj.pop_key] = obj;
        });

        if (_option.google_webmaster_tool.pop_value){
          this._meta.updateTag({ name: 'google-site-verification', content: _option.google_webmaster_tool.pop_value });
        }

        try{
          if (_option.facebook_domain_verify.pop_value){
            let current = _option.facebook_domain_verify
            let facebook_domain_verify = current.pop_value
            if(`${current.pop_value}`.match(/content="(.*)"/).length > 0){
              facebook_domain_verify = `${current.pop_value}`.match(/content="(.*)"/)[1]
            }
            if(`${current.pop_value}`.search(facebook_domain_verify) !== -1){
              this._meta.updateTag({ name: 'facebook-domain-verification', content: facebook_domain_verify });
            }
          }
        }catch(err){}

        if(!this._cookie.get('pdpa')){
          let pages = false
          let langs = false
          if(this._cookie.get('pages')) pages = true
          if(this._cookie.get('langs')) langs = true
          setTimeout(() => {
            let all = this._cookie.getAll()
            for(let key in all){
              this._cookie.remove(key)
            }
            if(pages) this._cookie.put('pages',`${pages}`)
            if(langs) this._cookie.put('langs',`${langs}`)
          },2000)
        }

        if (_option.google_tag_manager_head.pop_value) {
            $(_option.google_tag_manager_head.pop_value).appendTo('head');
        }
        if (_option.google_tag_manager_body.pop_value) {
          $(_option.google_tag_manager_body.pop_value).prependTo('body');
        }

        //console.log(_option);
        if (_option.track_script.pop_value){
          $(_option.track_script.pop_value).appendTo('head');
            // When loading `web-vitals` using a classic script, all the public
            // methods can be found on the `webVitals` global namespace.
            const sendToGoogleAnalytics = (data: any) => {
              let {name, delta, value, id} = data;
              console.log('sendToGoogleAnalytics', name, delta, id, data)
              // Assumes the global `ga()` function exists, see:
              // https://developers.google.com/analytics/devguides/collection/analyticsjs
              try{
                gtag('event', name, {
                  // Built-in params:
                  value: delta, // Use `delta` so the value can be summed.
                  // Custom params:
                  metric_id: id, // Needed to aggregate events.
                  metric_value: value, // Optional.
                  metric_delta: delta, // Optional.
                });
              }catch{}
              try{
                ga('send', 'event', {
                  eventCategory: 'Web Vitals',
                  eventAction: name,
                  // The `id` value will be unique to the current page load. When sending
                  // multiple values from the same page (e.g. for CLS), Google Analytics can
                  // compute a total by grouping on this ID (note: requires `eventLabel` to
                  // be a dimension in your report).
                  eventLabel: id,
                  // Google Analytics metrics must be integers, so the value is rounded.
                  // For CLS the value is first multiplied by 1000 for greater precision
                  // (note: increase the multiplier for greater precision if needed).
                  eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
                  // Use a non-interaction event to avoid affecting bounce rate.
                  nonInteraction: true,
                  // Use `sendBeacon()` if the browser supports it.
                  transport: 'beacon',
                  // OPTIONAL: any additional attribution params here.
                  // See: https://web.dev/debug-web-vitals-in-the-field/
                  // dimension1: '...',
                  // dimension2: '...',
                  // ...
                });
              }catch{}
            }                  
            WebVitals.onCLS(sendToGoogleAnalytics)
            WebVitals.onFCP(sendToGoogleAnalytics)
            WebVitals.onFID(sendToGoogleAnalytics)
            WebVitals.onINP(sendToGoogleAnalytics)
            WebVitals.onLCP(sendToGoogleAnalytics)
            WebVitals.onTTFB(sendToGoogleAnalytics)
        }

        clearInterval(this.timeCheckPDPA)
        this.timeCheckPDPA = setInterval(()=>{
          if(this._cookie.get('pdpa')){
            clearInterval(this.timeCheckPDPA)
            console.log('pdpa accepted')
            if (_option.tiktok_pixel.pop_value){
              $(_option.tiktok_pixel.pop_value).appendTo('head');
            }
    
            if (_option.line_pixel_head.pop_value){
              $(_option.line_pixel_head.pop_value).appendTo('head');
            }

            if (_option.google_adword_remarketing.pop_value) {
                $(_option.google_adword_remarketing.pop_value).prependTo('body');
            }
    

            if(_option.pixel_code_id.pop_value){
              var url = window.location.href
              if(url.search(`${window.location.origin}/sale-page/`) == -1){
                console.log('<!-- Facebook Pixel Code [FRONT-END] -->')
                $(`<!-- Facebook Pixel Code -->
                <script>
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
    
                </script>
                <noscript>
                <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=${_option.pixel_code_id.pop_value}&ev=PageView&noscript=1"/>
                </noscript>
                <!-- End Facebook Pixel Code -->`).appendTo('head');
    
    
    
    
                setTimeout(() => {
                  try{
                    fbq('init', `${_option.pixel_code_id.pop_value}`);
                    fbq('track', 'PageView');
                  }catch(err){}
                  try{
                    // this._util
                    let custom_data: any = {}
                    let fb_event_data: any = {}
                    if(this._util.getCookie('_fbp')){
                      fb_event_data['fbp'] = this._util.getCookie('_fbp')
                    }
                    if(this._util.getCookie('_fbc')){
                      fb_event_data['fbc'] = this._util.getCookie('_fbc')
                    }
                    if(this.activatedRoute.snapshot.queryParamMap.get('fbclid')){
                      fb_event_data['fbc'] = this.activatedRoute.snapshot.queryParamMap.get('fbclid')
                    }
                    this._util.fb_events({
                      ...fb_event_data,
                      action_source: 'website',
                      events: [
                        {
                          event_name: 'PageView',
                          event_url: location.href,
                          custom_data: custom_data
                        }
                      ]
                    }).then(()=>{}).catch(()=>{})
                  }catch(err){}
                  if (_option.line_pixel_event.pop_value) {
                    $(_option.line_pixel_event.pop_value).prependTo('body');
                  }
                },1500);
    
              }
            }

            setTimeout(() => {
              gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
              });
              console.log('gtag consent update')
            }, 1000);

          }
        },this._cookie.get('pdpa')?1:3000)
    }

}
