import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
// import th from '@angular/common/locales/th';
import { CookieModule } from 'ngx-cookie';
// import { TMTLayoutDirective } from './dirctives/layout.directive';
import { Etc } from './util/etc';
import { APIInterceptor } from './http.middleware';
import { FontFaceComponent } from './components/font-face';
import { Store } from './store/store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { NzUpdateHostClassService } from 'ng-zorro-antd/core/services/update-host-class.service';
import { StaticLangPipe } from './pipes/static-lang.pipe';
import { ModalEventPopupComponent } from './pages/modal/modal-event-popup/modal-event-popup.component';
import { AngularFireModule } from '@angular/fire';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FontSettingModule } from './system/font-setting/font-setting.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { ButtonSettingModule } from './system/button-setting/button-setting.module';
import { MenuModule } from './system/menu/menu.module';
import { BlogDisplayModule } from './system/blog-display/blog-display.module';
import { ProductDisplayModule } from './system/product-display/product-display.module';
import { SettingThemeModule } from './system/setting/setting-theme/setting-theme.module';
import { EmailModule } from './system/email/email.module';
import { DeliveryViewComponent } from './system/delivery-view/delivery-view.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { UrlSerializer } from '@angular/router';
import CustomUrlSerializer from './url-serialize';

registerLocaleData(en);

const customUrlSerializer = new CustomUrlSerializer();
const CustomUrlSerializerProvider = {
  provide: UrlSerializer,
  useValue: customUrlSerializer
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

let dev_module = [
  StoreDevtoolsModule.instrument({
    maxAge: 25, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode  
  })
]
if(environment.production){
  dev_module = []
}


@NgModule({
  declarations: [
    AppComponent,
    // TMTLayoutDirective,
    FontFaceComponent,
    StaticLangPipe,
    ModalEventPopupComponent,
    DeliveryViewComponent
  ],
  entryComponents:[
    ModalEventPopupComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    // BrowserModule.withServerTransition({appId: 'appServer'}),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(Store),
    AppRoutingModule,
    // TransferHttpCacheModule,
    HttpClientModule,
    // BrowserAnimationsModule,
    CookieModule.withOptions(),
    AngularFireModule.initializeApp(environment.firebase),
    FontSettingModule,
    ButtonSettingModule,
    MenuModule,
    BlogDisplayModule,
    ProductDisplayModule,
    SettingThemeModule,
    EmailModule,
    ...dev_module
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    CustomUrlSerializerProvider,
    Etc,
    NzUpdateHostClassService,
    StaticLangPipe,
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
