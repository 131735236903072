import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { CookieService } from 'ngx-cookie';
// import { Store } from '@ngrx/store';
// import { SET_PROVINCE, SET_LANGS } from '../store/action';
import * as moment from 'moment';

let FEATURES:any[] = []
@Injectable({
  providedIn: 'root'
})
export class FeatureService {
   private feature:any = null;
   private date_time:any;
  constructor(
    private _http:HttpClient
  ) { }

    async allFeature(){
        if (this.feature === null) {
            await this._init();
        }
        return this.feature;
    }

    async getFeature(key = null){
        if(this.feature === null){
            await this._init();
        }
        if(key){
            let _f = this.feature.find(x => x.name == key);
            if(key == "SALEPAGE"){
              if(!_f){
                return false;
              }
            }
            if(!_f){
                return true;
            }
            if (_f.hasOwnProperty("type") && _f.type) {
                if (_f.type == 'status') {
                    if (_f.value == "1") {
                        return true;
                    } else if (_f.value == "0") {
                        return false;
                    }
                } else if (_f.type == 'date') {
                    let daypass = moment(this.date_time).diff(moment(_f.value), 'second');
                    if (daypass > 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }else{
                if (_f.name == "FBCHAT" || _f.name == "PROMOTION" ||
                    _f.name == "PREMADE" || _f.name == "BLOG_PAGE" ||
                    _f.name == "PRODUCT_PAGE" || _f.name == "PREMADE" ||
                    _f.name == "CUSTOM_ELEMENT" || _f.name == "LINECHAT" ||
                    _f.name == "PROMOTION" || _f.name == "MEMBER_POINT" ||
                    _f.name == "FREE_ITEM" || _f.name == "SECTION_BOOTSTRAP" || _f.name == "OMNICHAT"
                ) {
                    if (_f.value == "1") {
                        return true;
                    } else if (_f.value == "0") {
                        return false;
                    } else {
                        let daypass = moment(this.date_time).diff(moment(_f.value), 'second');
                        if (daypass > 0) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else {
                    if (_f.value == "1") {
                        return true;
                    } else if (_f.value == "0") {
                        return false;
                    }
                }
            }

            

        }
        return this.feature;
    }

    async getFeatureValue(key: string) {
        const targetFeature: any = await this.getAllFeature();
        return targetFeature.find(x => x.name == key);
    }

    async getFormatDate(key: string) {
        const targetFeature = await this.getFeatureValue(key);
        if (!targetFeature || targetFeature && !targetFeature.hasOwnProperty('value')) return '';
        const regex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
        if (!regex.test(targetFeature.value)) return '';
        return moment(targetFeature.value).format('D MMM YYYY');
    }

    // setFeature(obj){
    //     this.feature = obj;
    //     Object.freeze(this.feature);
    // }
    getAllFeature(){
        return new Promise( async (s,j)=>{
            try {
                console.log('FEATURES.length',FEATURES.length)
                if(!FEATURES.length){
                    this._http.get('/util/featureConfig').toPromise().then(s).catch(j)
                }else{
                    s(FEATURES)
                }
            }catch(err){
                this._http.get('/util/featureConfig').toPromise().then(s).catch(j)
            }
        })
    }

    getDateTime(){
        return this._http.get('/util/datetime').toPromise();
    }

    async _init(){
        console.log('_init feature ');
        let date:any = await this.getDateTime();
        this.date_time = date.date_time;
        let obj = await this.getAllFeature();
        FEATURES = cloneDeep(obj)
        this.feature = obj;
        Object.freeze(this.feature);
        console.log(this.feature);
    }

}
