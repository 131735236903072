import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ExtensionService } from '@cms/services/extension.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { OrderService } from '@cms/services/order.service';
import {cloneDeep} from 'lodash';
import { Etc } from '@cms/util/etc';
import * as moment from 'moment';
import { IPeakAccount, PeakAccountService } from '@cms/services/peak-account.service';
declare var navigator: any

@Component({
  selector: 'app-extension-peak',
  templateUrl: './peak.component.html',
  styleUrls: ['./peak.component.css']
})
export class PeakComponent implements OnInit {
  @Output() back = new EventEmitter();
  constructor(
    private Service_Extension: ExtensionService,
    private message: NzMessageService,
    private modal: NzModalService,
    private Service_Order: OrderService,
    private _etc: Etc,
    private drawerRef: NzDrawerRef<string>,
    private peakAccountService: PeakAccountService
  ) { }
  tabselected: any = 'desc';
  model: any = {};
  message_duration: number = 4000;
  _temp_option:any;
  _temp_custom1:any;
  _temp_is_tax:boolean = true;
  allcreateby:any = [];
  modelsearch: any = {
    startdate: "",
    enddate: "",
    status: "",
    ordercode: "",
    email: "",
    trackcode: "",
    search: "",
    paymenttype: "",
    created_by: "",
    page: 1,
    perpage: 10,
    peak: true,
    peak_status: "1"
  }
  statusimg:any = [];
  usernameSearch:any='';
  paymentimg:any = [];
  loading:any = false;
  order:any = [];
  total:any = 0;
  peak_account: IPeakAccount.AccountsEntity = {
    option: 1,
    option_cod: 'include'
  }
  peak_model : {
    is_tax: boolean,
    option_cod: 'include' | 'exclude',
    option: 1 | 2 | 3,
    client_token: string
    status: number
  } = {
    is_tax: true,
    option_cod: 'include',
    option: 1,
    client_token: '',
    status: 0
  }

  async ngOnInit() {
    this.statusimg = this._etc.orderstatus_img();
    this.paymentimg = this._etc.payment_img();
    this.allcreateby = await this.Service_Order.getcreatedby();

    this.peakAccountService.accountInfo().then(async (res) => {
      if(!res) return
      this.peak_account = res
      this.peak_model.client_token = res.api_key
      this.peak_model.is_tax = res.is_tax?true:false
      this.peak_model.option_cod = res.option_cod
      this.peak_model.option = res.option
      this.peak_model.status = res.status
      // console.log('peak_model',this.peak_model)
      // console.log('peak_account',this.peak_account)
    }).catch(()=>{})

    // this.Service_Extension.search({ name: 'Peak' }).then((res: any) => {
    //   console.log(res);
    //   if(!res.hasOwnProperty('is_tax')){
    //     res['is_tax'] = true;
    //   }
    //   this.model = res;
    //   this._temp_is_tax = this.model['is_tax'];
    //   console.log(this.model);
    //   this._temp_option = (this.model.option != null)? this.model.option: "2";
    //   this._temp_custom1 = (this.model.custom1 != null) ? cloneDeep(this.model.custom1) : { cod: "include"};
    // }).catch(data => {

    // });

    window['_check_extenion']()

  }
  selectCustomer(obj: any) {
    console.log(obj)

    this.usernameSearch = `${obj.name} ${obj.lastname}`
    this.modelsearch.search = `${obj.username}`
    this.search();
  }
  resend(obj){

  }

  onSearchChange(e: any) {
    this.usernameSearch = `${e}`

    if (`${e}` == "") {
      this.modelsearch.search = ""
      this.search();
    }
  }
  search() {
    /*
    this.loading = true;
    this.Service_Order.search(this.modelsearch).then((res: any) => {
      console.log(res);
      this.order = res.data;
      this.total = res.count;
    }).catch((data) => {
      console.log(data.error.message);
    }).finally(() => {
      this.loading = false;
    });
    // console.log(this.rangePicker);
    */
  }
  pagechage(page) {
    this.modelsearch.page = page;
    this.search();
  }
  setSelected(tab) {
    this.tabselected = tab;
  }
  copylink(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success', { nzDuration: this.message_duration });
      });
    } catch (err) { }
  }
  change_key() {
    this.model.connected = 0;

  }

  delete_key(){
    this.modal.confirm({
      nzTitle: `ต้องการยกเลิกการเชื่อมต่อกับ Peak Account?`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        $('.theme-loader').show();
        this.peak_model.status = 0
        this.peakAccountService.connectAndDisconnect(this.peak_model).then((res: any) => {
          this.ngOnInit();
          $('.theme-loader').fadeOut();
        }).catch((err)=>{
          $('.theme-loader').fadeOut();
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => { }
    });
  }

  save(){
    // this.model.option = this._temp_option;
    // this.model.custom1 = this._temp_custom1;
    // this.model.is_tax = this._temp_is_tax;
    this.connect();

  }
  
  connect(){
    $('.theme-loader').fadeIn();
    this.peak_model.status = 1
    this.peakAccountService.connectAndDisconnect(this.peak_model).then((res: any) => {
      this.ngOnInit();
      $('.theme-loader').fadeOut();
    }).catch((err)=>{
      $('.theme-loader').fadeOut();
      this.modal.warning({
        nzTitle: 'ไม่สามารถเชื่อมต่อกับ Peak Account ได้',
        nzContent: 'กรุณาตรวจสอบการเชื่อมต่อกับ Peak Account อีกครั้ง',
        nzOnOk: () => { }
      });
    })
  }

  _back() {
    this.drawerRef.close();
  }

}
