import { Injectable } from '@angular/core';
export namespace IGoogleAnalytics{
    export type LoginType = "Google"|"Facebook"|"Line"|"Email"
    export interface Items{
        item_id:string;
        item_name:string;
        affiliation?:string;
        coupon?:string;
        currency?:string;
        discount?:number;
        index?:number;
        item_brand?:string;
        item_category?:string;
        item_category2?:string;
        item_category3?:string;
        item_category4?:string;
        item_category5?:string;
        item_list_id?:string;
        item_list_name?:string;
        item_variant?:string;
        location_id?:string;
        price?:number;
        quantity?:number;
    }

    export interface IPurchase{
        currency:string;
        transaction_id:string;
        value:number;
        affiliation?:string;
        coupon?:string;
        shipping?:number;
        tax?:number;
        items:IGoogleAnalytics.Items[]
    }

    export interface IPromotion{
        creative_name?:string;
        creative_slot?:string;
        location_id?:string;
        promotion_id?:string;
        promotion_name?:string;
        items?:IGoogleAnalytics.Items[];
    }

}

@Injectable({
    providedIn: 'root'
  })
  export class GoogleAnalyticsService {
  
    constructor(
    ) { }
    
    getInstance(){
        if(!window['gtag']){
            throw Error("gtag not found");
        }
        return window['gtag'];
    }
    //  select_content

    login(method:IGoogleAnalytics.LoginType){
        try{
            let gtag = this.getInstance();
            gtag("event", "login", {
                method: method
            });
        }catch(e){
            // console.log(e.message);
        }
    }

    sign_up(method:IGoogleAnalytics.LoginType){
        try{
            let gtag = this.getInstance();
            gtag("event", "sign_up", {
                method: method
            });
        }catch(e){ }
    }

    search(search_term:string = ""){
        try{
            if(search_term === ""){return;}
            let gtag = this.getInstance();
            gtag("event", "search", {
                search_term: search_term
            });
        }catch(e){ }
    }

    view_item(data:{
        currency:string;
        value:number;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "view_item",data);
        }catch(e){ }
    }

    view_cart(data:{
        currency:string;
        value:number;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "view_cart",data);
        }catch(e){ }
    }

    add_to_cart(data:{
        currency:string;
        value:number;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "add_to_cart",data);
        }catch(e){ }
    }

    remove_from_cart(data:{
        currency:string;
        value:number;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "remove_from_cart",data);
        }catch(e){ }
    }

    purchase(data:IGoogleAnalytics.IPurchase){
        try{
            let gtag = this.getInstance();
            gtag("event", "purchase",data);
        }catch(e){ }
    }
    refund(data:IGoogleAnalytics.IPurchase){
        try{
            let gtag = this.getInstance();
            gtag("event", "refund",data);
        }catch(e){ }
    }

    begin_checkout(data:{
        currency:string;
        value:number;
        coupon?:string;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "begin_checkout",data);
        }catch(e){ }
    }

    add_shipping_info(data:{
        currency:string;
        value:number;
        coupon?:string;
        shipping_tier?:string;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "add_shipping_info",data);
        }catch(e){ }
    }

    add_payment_info(data:{
        currency:string;
        value:number;
        coupon?:string;
        payment_type?:string;
        items:IGoogleAnalytics.Items[];
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "add_payment_info",data);
        }catch(e){ }
    }

    select_promotion(data:IGoogleAnalytics.IPromotion){
        try{
            let gtag = this.getInstance();
            gtag("event", "select_promotion",data);
        }catch(e){ }
    }

    select_content(data:{
        content_type?:string;
        item_id?:string;
    }){
        try{
            let gtag = this.getInstance();
            gtag("event", "select_content",data);
        }catch(e){ }
    }

    page_view(data:{
        page_title:string;
        page_location:string
    }){
        try{
            let gtag = this.getInstance();
            for(var key in window['google_tag_manager']){
                if(`${key}`.search('UA-') !== -1 || `${key}`.search('G-') !== -1){
                    gtag('config', key, data);
                }
            }
        }catch(e){ }
    }




}