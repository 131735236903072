import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ketJoin'
})
export class KetJoinPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string[], delimeter: string = "", filterFunc?: Function): any {
    if (filterFunc) {
      return value.filter(x => filterFunc(x)).join(delimeter)
    } else {
      return value.join(delimeter)
    }
  }
  
}
