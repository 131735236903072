import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export namespace IShopee {
  export interface Shop {
    id: number;
    shop_id: string
    shop_name: string
    peak_account: any
    cus_id: number
  }
}


@Injectable({
    providedIn: 'root'
  })
  export class ShopeeService {
  
    constructor(
      private _http: HttpClient
    ) { }
  
    shippingDocument(data:any):Promise<any>{
      return this._http.post('/services/shopee/order/shippingDocument',data).toPromise() as Promise<any>;
    }

    getShippingParameter(data:any):Promise<any>{
      return this._http.post('/services/shopee/order/getShippingParameter',data).toPromise() as Promise<any>;
    }
    deliveredShopeeOrderBatch(data:any):Promise<any>{
      return this._http.post('/services/shopee/order/deliveredOrderBatch',data).toPromise() as Promise<any>;
    }
    deliveredShopeeOrder(data:any):Promise<any>{
      return this._http.post('/services/shopee/order/deliveredShopeeOrder',data).toPromise() as Promise<any>;
    }
    checkConnection(): Promise<any> {
      return this._http.get("/services/shopee/shop/checkConnection").toPromise() as Promise<any>
    }
    syncProductsState(shop_id:number):Promise<any> {
      return this._http.get('/services/shopee/product/syncProductsState/' + shop_id).toPromise() as Promise<any>;
    }
    syncProducts(shop_id:number):Promise<any>{
      return this._http.get('/services/shopee/product/syncProducts/' + shop_id).toPromise() as Promise<any>;
    }
    unListItem(data:any):Promise<any>{
      return this._http.post('/services/shopee/product/unListItem/',data).toPromise() as Promise<any>;
    }
    searchSyncProduct(data:any,shop_id:number):Promise<any>{
      return this._http.post('/services/shopee/product/searchSyncProduct/'+shop_id,data).toPromise() as Promise<any>;
    }
    searchKetshopShopeeOrder(data:any): Promise<any>{
      return this._http.post("/services/shopee/order/searchKetshopShopeeOrder",data).toPromise() as Promise<any>
    }
    syncOrdersState(){
      return this._http.get('/services/shopee/order/syncOrdersState').toPromise() as Promise<any>;
    }
    syncOrders(){
      return this._http.get('/services/shopee/order/syncOrders').toPromise() as Promise<any>;
    }
    syncOrderByOrderNumber(data){
      return this._http.post('/services/shopee/order/syncOrderByOrderNumber',data).toPromise() as Promise<any>;
    }
    createOrderKetshop(data){
      return this._http.post('/services/shopee/order/createOrderKetshop',data).toPromise() as Promise<any>;
    }
    listShop(): Promise<any>{
      return this._http.get("/services/shopee/shop/listShop").toPromise() as Promise<any>
    }

    getCategory(shop_id:number): Promise<any>{
      return this._http.get("/services/shopee/product/getCategory/"+shop_id).toPromise() as Promise<any>
    }
    authorizationShopUrl(): Promise<any>{
      return this._http.get("/services/shopee/shop/authorizationShopUrl").toPromise() as Promise<any>
    }

    searchKetshopProductToShopee(data:any): Promise<any>{
      return this._http.post("/services/shopee/product/searchKetshopProductToShopee",data).toPromise() as Promise<any>
    }
    searchKetshopShopeeProduct(data:any): Promise<any>{
      return this._http.post("/services/shopee/product/searchKetshopShopeeProduct",data).toPromise() as Promise<any>
    }
    getProductShopeeByItemID(item_id){
      return this._http.get("/services/shopee/product/byItemID/"+item_id).toPromise() as Promise<any>
    }
    uploadImage(data:any): Promise<any>{
      return this._http.post("/services/shopee/product/uploadImage",data).toPromise() as Promise<any>
    }
    
    createProduct(data:any): Promise<any>{
      return this._http.post("/services/shopee/product/createProduct",data).toPromise() as Promise<any>
    }
    updateProduct(data:any): Promise<any>{
      return this._http.post("/services/shopee/product/updateProduct",data).toPromise() as Promise<any>
    }

    cancelAuthorizationShopUrl(): Promise<any>{
      return this._http.get("/services/shopee/shop/cancelAuthorizationShopUrl").toPromise() as Promise<any>
    }
    getLogistic(shop_id:number):Promise<any>{
      return this._http.get("/services/shopee/product/getLogistic/"+shop_id).toPromise() as Promise<any>
    }

    searchBrand(data:any):Promise<any>{
      return this._http.post("/services/shopee/product/searchBrand",data).toPromise() as Promise<any>
    }
    getCategoryAttribute(data:any):Promise<any>{
      return this._http.post("/services/shopee/product/categoryAttribute",data).toPromise() as Promise<any>
    }

   // update_peak_account
    updatePeakAccount(model: {
      id: number,
      peak_account: any
    }) {
      return this._http.post(`/services/shopee/shop/update_peak_account`, model).toPromise() as Promise<any>;
    }

  }