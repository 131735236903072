import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient } from '@angular/common/http';

let memory_cache: any = {
  search_front: {}
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private _etc:Etc,
    private _http:HttpClient
    ) { }

  // searchProductLogShopee()

  cacheCall(_promise: Observable<any>, key1: string, key2: string = null){
    return new Promise((resolve, reject)=>{
      if(key2){
        // console.log('cacheCall',key1,key2, 'memory_cache[key1][key2]', memory_cache[key1][key2]);
        if(memory_cache[key1][key2]){
          return resolve(memory_cache[key1][key2]);
        }else{
          console.log('_promise 1')
          return _promise.toPromise().then((res)=>{
            memory_cache[key1][key2] = res;
            resolve(res);
          }).catch((err)=>{
            reject(err);
          })
        }
      }
      if(memory_cache[key1]){
        return resolve(memory_cache[key1])
      }else{
        console.log('_promise 2')
        _promise.toPromise().then(res=>{
          memory_cache[key1] = res;
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }
    })
  }

  getAllProductBySort(){
    return this._http.get('/product/allProductBySort').toPromise();
  }

  updateProductSort(data){
    return this._http.post('/product/updateSort', data).toPromise();
  }

    searchProductReport(data:any){
      return this._http.post('/warehouse', data).toPromise();
    }

    searchProductLogJD(data: any) {
      return this._http.post('/product/searchLogJD', data).toPromise();
    }

    searchProductReportSold(data: any) {
      return this._http.post('/report/productReportSold', data).toPromise();
    }
    searchProductStockMgmt(data:any){
      return this._http.post('/product/searchProductStockMgmt',data).toPromise();
    }
    getProductStockMgmtByID(data:any){
      return this._http.post('/product/getProductStockMgmtByID', data).toPromise();
    }
    updateProductStockMgmt(data:any) {
      return this._http.post('/product/updateProductStockMgmt', data).toPromise();
    }
    resendUpdateStockShopee(data:any){
      return this._http.post('/product/resendUpdateStockShopee', data).toPromise();
    }
    resendUpdateStockLazada(data:any){
      return this._http.post('/product/resendUpdateStockLazada', data).toPromise();
    }
    resendUpdateStockJD(data: any) {
      return this._http.post('/product/resendUpdateStockJD', data).toPromise();
    } 
    searchUpdateStockLog(data:any){
      return this._http.post('/product/searchUpdateStockLog', data).toPromise();
    }
    searchProduct(data:any): Promise<any>{
      return this._http.post('/product/search', data).toPromise() as any;
    }

    searchProductFront(data:any){
      let key = JSON.stringify(data);
      return this.cacheCall(this._http.post('/product/searchFront', data), 'search_front', key);
      // return this._http.post('/product/searchFront', data).toPromise();
    }

    productMainCategory(){
      return this._http.get('/product/maincategory').toPromise();
    }

    productCategory(){
      return this._http.get('/product/category').toPromise();
    }

    updateProductCategory(obj) {
      return this._http.put('/product/category/' + obj.id, obj).toPromise();
    }

    updateProductToCategoryMultiple(obj){
      return this._http.post('/product/categoryUpdateMultiple',obj).toPromise();
    }

    createProductCategory(obj){
      return this._http.post('/product/category',obj).toPromise();
    }

    allCategory() {
      return this._http.get('/product/allCategory').toPromise();
    }

    deleteProductCategory(id){
      return this._http.delete('/product/category/'+id).toPromise();
    }

    updateProductCategorySort(obj){
      return this._http.post('/product/categorySort',obj).toPromise();
    }

    searchProductGroup(data:any){
      return this._http.post('/product/searchGroup',data).toPromise();
    }

    searchProductTag(data: any) {
      return this._http.post('/tags/search', data).toPromise();
    }

    listProductTag(){
      return this._http.get('/product/listProductTag').toPromise();
    }
    
    listProductPriceRange(){
      return this._http.get('/product/listProductPriceRange').toPromise();
    }

    searchProductLogShopee(data:any){
      return this._http.post('/product/searchLogShopee', data).toPromise();
    }

    searchProductLog(data: any) {
      return this._http.post('/product/searchLog', data).toPromise();
    }

    updateProduct(data:any){
      return this._http.put(`/product/${data.id}`, data).toPromise();
    }

    createProduct(data:any){
      return this._http.post(`/product`, data).toPromise();
    }

    getProductByID(id){
      return this._http.get(`/product/${id}`).toPromise();
    }

    productMarketPlace(id) {
      return this._http.get(`/product/productMarketPlace/${id}`).toPromise();
    }
    
    getProductPropertiesObj(id){
      return this._http.get(`/product/propertiesobj/${id}`).toPromise();
    }
    clearProductProperties(obj){
      return this._http.post(`/product/clearproperties`,obj).toPromise();
    }

    deleteProduct(obj){
      return this._http.delete(`/product/${obj.id}`).toPromise();
    }

    clondProduct(obj){
      return this._http.post(`/product/clone`, { product_id: obj.id}).toPromise();
    }

    getCategoryIDS(obj:any){
      return this._http.post('/product/searchCategoryByID',obj).toPromise();
    }

    getProductDetail(data:any){
      return this._http.post('/product/productDetail', data).toPromise();
    }

    getProductGroup(data:any){
      return this._http.post('/product/productGroup', data).toPromise();
    }

    getProductRelated(data:any){
      return this._http.post('/product/productRelated', data).toPromise();
    }

    searchProductIndex(data:any){
      return this._http.post('/product/searchProductIndex', data).toPromise();
    }

    operation(data: any) {
      return this._http.post('/product/operation', data).toPromise();
    }

    searchProductLogActivity(data: any) {
      return this._http.post('/report/productLogActivity', data).toPromise();
    }

    searchProductLogLazada(data:any){
      return this._http.post('/product/searchLogLazada', data).toPromise();
    }

    searchProductReserveLog(data: any) {
      return this._http.post('/product/searchReserveLog', data).toPromise();
    }

    searchProductReportInstock(data: any) {
      return this._http.post('/report/productReportInstock', data).toPromise();
    }

    deleteReview(id: any) {
      return this._http.delete(`/product/review/${id}`).toPromise();
    }

    searchReview(data: any) {
      return this._http.post('/product/review/search', data).toPromise();
    }

    createReply(data: any) {
      return this._http.post(`/product/review/reply`, data).toPromise();
    }

    updateReply(id: any, data: any) {
      return this._http.put(`/product/review/reply/${id}`, data).toPromise();
    }

    createReview(data: any) {
      return this._http.post('/product/review', data).toPromise();
    }

    frontendCreateReview(data: any) {
      return this._http.post('/product/review/frontend', data).toPromise();
    }

    pageProductCreateReview(data: any) {
      return this._http.post('/product/review/pageProduct', data).toPromise();
    }

    searchProductByOrder(data: any) {
      return this._http.post('/product/searchByOrder', data).toPromise();
    }
}
