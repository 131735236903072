import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Packer } from '@cms/services/packer';
import { UPDATE_PRODUCT_FROM_CART } from '@cms/store/action';

let box_sizes:any[] = []

export interface ISummary{
    vat: number;
    subtotal: number;
    use_point: number;
    total: number;
    vatvalue: number;
    selecteddelivery: any;
    discountaddon: number;
    promotion_detail: any;
    discount: number;
    point_discount: number;
    freeshipping: number;
    input_coupon: boolean;
    delichargeaddon: number;
    cod_value: number;
    use_coupon: boolean;
    free_item: any;
}

export interface ICalculateDiscount{
    summary: ISummary;
    deliverys: any[];
    freeshipping_cod: any[];
    shipping_avalible: boolean;
}

type IBOOL_NUM = boolean | number;

export interface IModelOrder{
    delivery_option?: {
      shipping_type_price: string; //free,custom,normal
      price: number
    },
    from:  string;
    user_id: number,
    shipping_address_id: number;
    details: any[];
    coupon_code: string;
    customer_note: string;
    note: string;
    discountaddon: number;
    tax_no: string;
    guest: any;
    use_point: number;
    delivery: any;
    channel?: string;
    salepage?:any;
    quantity: number;
    redirect?: any,
    consent_terms_privacy_policy: IBOOL_NUM;
}
 
export interface IModelLinkOrder{
  from:  string;
  country_alpha2_code: string;
  country_name: string;
  shipping_address_id: number;
  details: any[];
  coupon_code: string;
  customer_note: string;
  tax_no: string;
  guest: any;
  use_point: number;
  delivery: any;
  orderlink_id: number;
  orderlink_permalink: string;
  quantity: number;
  payment?: any;
  consent_terms_privacy_policy: IBOOL_NUM;
}

export interface IModelOpenLink{
    id: number;
    user_id: number;
    shipping_address_id: number;
    country_name: string; 
    country_alpha2_code: string;  
    details: any[];
    note: string;
    channel: string; 
    discountaddon: number;
    use_point: number;
    delivery: any;
    social_id: string;
    customer: any;
    slip:any;
    is_payment: boolean;
    delivery_free_price: boolean;
    channel_social_name?: string;
    channel_social_id?: string;
    tax_no: string;
}

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(
        private _http: HttpClient,
    ) { }
    exportMarketplaceDiscount(data){
      return this._http.post('/order/exportMarketplaceDiscount', data).toPromise();
    }
    checkOrderPendingUpdate(data: any) {
      return this._http.post('/order/checkOrderPendingUpdate', data).toPromise();
    }
  editOrderBillAddress(data:any){
    return this._http.post('/order/editOrderBillAddress',data).toPromise();
  }
  editOrderShippingAddress(data:any){
    return this._http.post('/order/editOrderShippingAddress',data).toPromise();
  }
  listOrderMarketPlaceShop(){
    return this._http.get('/order/listOrderMarketPlaceShop').toPromise();
  }

  searchZortLog(data:any){
    return this._http.post('/order/searchZortLog', data).toPromise();
  }
  resendZort(data:any){
    return this._http.post('/order/resendZort', data).toPromise();
  }

  search(data: any) {
    return this._http.post('/order/search', data).toPromise();
  }
  listOrders(data: any) {
    return this._http.post('/order/listOrders', data).toPromise();
  }

  searchSaleByChannel(id, data: any) {
    return this._http.post(`/order/userChannelSummary/${id}`, data).toPromise();
  }
  orderLinkAccesstokenGuest(data: any) {
    return this._http.post('/order-link/accesstokenGuest', data).toPromise();
  }
  listOrderLinkChannelSocialName(){
    return this._http.get('/order-link/listOrderLinkChannelSocialName').toPromise();
  }
  searchByWithDraw(data: any) {
    return this._http.post('/order/searchByWithdraw', data).toPromise();
  }
  orderByID(id) {
    return this._http.get('/order/' + id).toPromise();
  }
  orderByOrderCodeChat(code, type = "facebook") {
    return this._http.get(`/order/${code}?type=ordercode&chat=${type}`).toPromise();
  }
  orderByOrderCode(code) {
    return this._http.get('/order/' + code + '?type=ordercode').toPromise();
  }
  orderByTrackingCode(code) {
    return this._http.get('/order/' + code + '?type=trackcode').toPromise();
  }
  updateOrderStatus(id, obj) {
    return this._http.put('/order/updatestatus/' + id, obj).toPromise();
  }
  checkOrderStatus(id) {
    return this._http.get('/order/checkstatus/' + id).toPromise();
  }
  checkOrderQrStatus(id) {
    return this._http.get('/order/checkOrderQrStatus/' + id).toPromise();
  }
  getcreatedby() {
    return this._http.get('/order/createdby').toPromise();
  }
  orderreportstatus(obj) {
    return this._http.post('/order/order-report-status', obj).toPromise();
  }

  marketplaceRemoveOrderFromShippingGroup(obj) {
    return this._http.post('/order/marketplaceRemoveOrderFromShippingGroup', obj).toPromise();
  }

  marketplaceDeliveryPrint(obj) {
    return this._http.post('/order/marketplaceDeliveryPrint', obj).toPromise();
  }

  clearBoxSize() {
    box_sizes = []
  }

  async discountanddelivery(obj: any) {
    obj['box_value'] = null
    try {
      let details: any[] = []
      for (let prod of obj.details) {
        for (let i = 0; i < prod.qty; i++) {
          // console.log(prod)
          let detail: any = {
            size_width: prod.size_width || 1,
            size_length: prod.size_length || 1,
            size_height: prod.size_height || 1
          }

          details.push(detail)
        }
      }
      try {
        if (box_sizes.length == 0) {
          let _box_sizes = await this._http.get('/delivery/boxs').toPromise() as any[]
          // console.log('_box_sizes', _box_sizes)
          box_sizes = _box_sizes
        }
      } catch (err) { }
      let boxsize = await new Packer().getBox(details, box_sizes)
      console.log('boxsize', boxsize)
      obj['box_value'] = boxsize.value
    } catch (err) { }
    return this._http.post('/order/checkdelivery', obj).toPromise()
  }

  async createOrderCredit(obj,ordercode = null) {
    obj['model']['box_value'] = null
    try {
      let details: any[] = []
      for (let prod of obj['model'].details) {
        for (let i = 0; i < prod.qty; i++) {
          // console.log(prod)
          let detail: any = {
            size_width: prod.size_width || 1,
            size_length: prod.size_length || 1,
            size_height: prod.size_height || 1
          }

          details.push(detail)
        }
      }
      try {
        if (box_sizes.length == 0) {
          let _box_sizes = await this._http.get('/delivery/boxs').toPromise() as any[]
          // console.log('_box_sizes', _box_sizes)
          box_sizes = _box_sizes
        }
      } catch (err) { }
      let boxsize = await new Packer().getBox(details, box_sizes)
      console.log('boxsize', boxsize)
      obj['model']['box_value'] = boxsize.value
    } catch (err) { }
    console.log('order_data', obj)
    // return new Promise((resolve, reject)=>{
    //   reject('errr')
    // })
    if(ordercode){
      obj['ordercode'] = ordercode
    }
    return this._http.post('/order/order-credit', obj).toPromise();
  }

  async createOrderFromOrderLink(obj: any) {
    obj['box_value'] = null
    try {
      let details: any[] = []
      for (let prod of obj.details) {
        for (let i = 0; i < prod.qty; i++) {
          // console.log(prod)
          let detail: any = {
            size_width: prod.size_width || 1,
            size_length: prod.size_length || 1,
            size_height: prod.size_height || 1
          }

          details.push(detail)
        }
      }
      try {
        if (box_sizes.length == 0) {
          let _box_sizes = await this._http.get('/delivery/boxs').toPromise() as any[]
          // console.log('_box_sizes', _box_sizes)
          box_sizes = _box_sizes
        }
      } catch (err) { }
      let boxsize = await new Packer().getBox(details, box_sizes)
      console.log('boxsize', boxsize)
      obj['box_value'] = boxsize.value
    } catch (err) { }
    return this._http.post('/order/orderlink-create', obj).toPromise();
  }

  async createOrderTransfer(obj) {
    obj['box_value'] = null
    try {
      let details: any[] = []
      for (let prod of obj.details) {
        for (let i = 0; i < prod.qty; i++) {
          // console.log(prod)
          let detail: any = {
            size_width: prod.size_width || 1,
            size_length: prod.size_length || 1,
            size_height: prod.size_height || 1
          }

          details.push(detail)
        }
      }
      try {
        if (box_sizes.length == 0) {
          let _box_sizes = await this._http.get('/delivery/boxs').toPromise() as any[]
          // console.log('_box_sizes', _box_sizes)
          box_sizes = _box_sizes
        }
      } catch (err) { }
      let boxsize = await new Packer().getBox(details, box_sizes)
      console.log('boxsize', boxsize)
      obj['box_value'] = boxsize.value
    } catch (err) { }
    return this._http.post('/order/order-transfer', obj).toPromise();
  }


  async createOrderQr(obj) {
    obj['box_value'] = null
    try {
      let details: any[] = []
      for (let prod of obj.details) {
        for (let i = 0; i < prod.qty; i++) {
          // console.log(prod)
          let detail: any = {
            size_width: prod.size_width || 1,
            size_length: prod.size_length || 1,
            size_height: prod.size_height || 1
          }

          details.push(detail)
        }
      }
      try {
        if (box_sizes.length == 0) {
          let _box_sizes = await this._http.get('/delivery/boxs').toPromise() as any[]
          // console.log('_box_sizes', _box_sizes)
          box_sizes = _box_sizes
        }
      } catch (err) { }
      let boxsize = await new Packer().getBox(details, box_sizes)
      console.log('boxsize', boxsize)
      obj['box_value'] = boxsize.value
    } catch (err) {
      console.log('Packer', err)
    }
    return this._http.post('/order/order-qr', obj).toPromise();
  }
  searchOrderLink(data) {
    return this._http.post('/order-link/search', data).toPromise();
  }
  createOrderLink(data) {
    return this._http.post('/order-link', data).toPromise();
  }
  deleteOrderLink(obj: any) {
    return this._http.delete(`/order-link/${obj.id}`).toPromise();
  }

  orderLinkbyID(id, type: string = '') {
    return this._http.get(`/order-link/${id}?type=${type}`).toPromise();
  }
  updateOrderLink(obj) {
    return this._http.put('/order-link/' + obj.id, obj).toPromise();
  }
  orderLinkSocial() {
    return this._http.get('/order-link/social').toPromise();
  }
  updateAddressOrder(data: any) {
    return this._http.post('/order/update-address', data).toPromise();
  }
  searchorderdeliveryprint(data) {
    return this._http.post('/order/searchorderdeliveryprint', data).toPromise();
  }

  webdeliveryprint(data: FormData) {
    return this._http.post('/order/orderdeliveryprint-pdf-website', data).toPromise();
  }

  availablePayment(totals = 0) {
    return this._http.get(`/order/availablePayment${totals > 0 ? `?totals=${totals}` : ''}`).toPromise();
  }

  ordercode(ordercode: any) {
    return this._http.get(`/order/page/${ordercode}`).toPromise();
  }

  check_order_credit(transaction: any, type: any = 'check_order_credit') {
    return this._http.get(`/order/check_order_credit/${transaction}?type=${type}`).toPromise();
  }

  editOrderAddress(data) {
    return this._http.post('/order/editOrderAddress', data).toPromise();
  }

  getGeocoding(data) {
    return this._http.post('/order/getGeocoding', data).toPromise();
  }

  feedbackGeo(data) {
    return this._http.post('/order/sendFeedbackGeo', data).toPromise();
  }

  generateCryptoLinkFromOrder(model: any) {
    return this._http.post('/order/generateCryptoLinkFromOrder', model).toPromise();
  }

  getLastAddresssFromSocialID(social_id: any, provider_id: string): Promise<{
    id: number;
    username: string;
    name: string;
    lastname: string;
    address1: string;
    subdistrict: string;
    district: string;
    province: string;
    zipcode: string;
    tel: any;
  }[]> {
    return this._http.get(`/order-link/lastAddressFromSocial/${social_id}/${provider_id}`).toPromise() as any;
  }

  getOrderModel(): IModelOrder {
    return {
      delivery_option: {
        shipping_type_price: "normal", //free,custom,normal
        price: 0
      },
      channel: null,
      salepage: null,
      from: 'Website',
      user_id: null,
      shipping_address_id: -1,
      details: [],
      coupon_code: "",
      customer_note: "",
      note: "",
      tax_no: "",
      guest: false,
      use_point: 0,
      discountaddon: 0,
      delivery: null,
      quantity: 0,
      consent_terms_privacy_policy: 0
    }
  }

  getOrderLinkModel(): IModelLinkOrder {
    return {
      from: 'OrderLink',
      shipping_address_id: -1,
      country_alpha2_code: 'TH',
      country_name: 'Thailand',
      details: [],
      coupon_code: "",
      customer_note: "",
      tax_no: "",
      guest: false,
      use_point: 0,
      delivery: null,
      orderlink_id: null,
      orderlink_permalink: null,
      quantity: 0,
      payment: {},
      consent_terms_privacy_policy: 0
    }
  }

  getModelOpenLink(): IModelOpenLink {
    return {
      id: null,
      user_id: -1,
      country_name:"Thailand",
      country_alpha2_code:"TH",
      shipping_address_id: -1,
      details: [],
      note: "",
      channel: "",
      discountaddon: 0,
      use_point: 0,
      delivery: null,
      social_id: "",
      customer: null,
      slip: null,
      is_payment: false,
      delivery_free_price: false,
      channel_social_name: null,
      channel_social_id: null,
      tax_no: ""
    }
  }


  getSummaryModel(): ISummary {
    return {
      vat: null,
      subtotal: 0,
      use_point: 0,
      total: 0,
      vatvalue: 0,
      selecteddelivery: null,
      discountaddon: 0,
      promotion_detail: [],
      input_coupon: false,
      discount: 0,
      point_discount: 0,
      freeshipping: 0,
      free_item: [],
      delichargeaddon: 0,
      cod_value: 0,
      use_coupon: false
    }
  }

  clearsummary(): ISummary {
    return this.getSummaryModel()
  }

  calculateDiscount(summary: ISummary, model: any, check_from: string = 'order'): Promise<ICalculateDiscount> {
    console.log('a29292929292wef');
    return new Promise((resolve, reject) => {
      if (model.details.length == 0) {
        resolve({
          summary: this.clearsummary(),
          deliverys: [],
          freeshipping_cod: [],
          shipping_avalible: false
        })
      }
      model['check_from'] = check_from
      this.discountanddelivery(model).then((res: any) => {

        summary.vat = res.vatrate;
        summary.promotion_detail = res.promotion_detail;
        summary.free_item = res.free_item;
        summary.input_coupon = res.input_coupon;
        summary.use_coupon = res.use_coupon;
        summary.discount = res.discount;
        summary.freeshipping = res.freeshipping;
        console.log(summary);
        console.log('1-1')
        //if (summary.selecteddelivery){
        //  let _selectdeli = res.deliverys.find(x => x.id == summary.selecteddelivery.id);
        //  if (_selectdeli){
        //    summary.selecteddelivery = _selectdeli;
        //  }else{
        //    summary.selecteddelivery = null;
        //  }
        //} 

        let shipping_avalible = false
        console.log('1-2')
        console.log(summary)
        console.log(summary.freeshipping)
        console.log(res)
        if (summary.freeshipping !== 1) {
          if (res.deliverys.length == 0) {
            summary.selecteddelivery = null;
          }
          if (res.deliverys.length == 0) {
            shipping_avalible = false
          } else {
            shipping_avalible = true
          }

        }
        console.log('1-3')
        for (let obj of model.details) {
          let _obj = res.details.find(x => x.product_id == obj.product_id)
          if (_obj) {
            obj.product_price = _obj.product_price
            obj.price = _obj.price
            obj.instock = _obj.instock
            console.log('1-4')
            try {
              var data: any = JSON.parse(localStorage.getItem('CART_STORE'))
              console.log('1-5')
              if (data) {
                var check_product: any = data.details.find(x => x.product_id == obj.product_id)
                if (check_product !== -1) {
                  check_product.product_price = obj.product_price
                  if (check_product.qty > check_product.instock) {
                    check_product.qty = check_product.instock
                  }
                  check_product.price = (check_product.product_price * check_product.qty)
                  localStorage.setItem('CART_STORE', JSON.stringify(data))
                }
              }
            } catch (err) {

            }
          }
        }

        resolve({
          summary: summary,
          deliverys: res.deliverys,
          freeshipping_cod: res.freeshipping_cod,
          shipping_avalible: shipping_avalible
        })
      }).catch((data) => {
        console.log(data)
        reject({
          error: {
            message: data.error.message
          }
        })
      })
    })

  }

  calculatepricedetail(summary: ISummary, model: any, point_discount: number) {
    // console.log('summary.selecteddelivery', summary.selecteddelivery.price)
    // console.log(JSON.stringify(summary))
    // console.log(JSON.stringify(model))

    if (model.hasOwnProperty('delivery_option') && summary.selecteddelivery) {
      // delivery_option: {
      //   shipping_type_price: "normal", //free,custom,normal
      //   price: 0
      // },
      switch (model.delivery_option.shipping_type_price) {
        case 'normal':

          break;
        case 'free':
          summary.freeshipping = 1
          break;
        case 'custom':
          summary.selecteddelivery.price = Number(model.delivery_option.price)
          break;
        default:
          break;
      }
    }

    let subtotal = 0;
    let deliprice = 0;
    if (summary.selecteddelivery && summary.freeshipping == 0) {
      deliprice = Number(summary.selecteddelivery.price);
    }
    for (let i = 0; i < model.details.length; i++) {
      let current = model.details[i];
      subtotal += (Number(current.product_price) * Number(current.qty));
    }
    summary.subtotal = subtotal;
    let sum_subtotal = (subtotal - Number(summary.discountaddon) - Number(summary.discount) - Number(point_discount))
    if (sum_subtotal < 0) {
      sum_subtotal = 0;
    }
    summary.vatvalue = (Number(summary.vat) * 0.01) * sum_subtotal;
    let total = subtotal - Number(summary.discount) - Number(summary.discountaddon) - Number(point_discount) + summary.vatvalue;
    if (total < 0) {
      total = 0;
    }

    summary.total = total + deliprice;
    if (summary.total < 0) {
      summary.total = 0;
    }

    summary.use_point = model.use_point
    summary.point_discount = point_discount;

    if (summary.selecteddelivery && summary.selecteddelivery.cod) {
      let cod_rate = 0;

      if (summary.selecteddelivery.cod_rate_type == 'percent') {
        cod_rate = +summary.selecteddelivery.cod_rate * 0.01;
        summary.cod_value = summary.total * cod_rate;
      } else if (summary.selecteddelivery.cod_rate_type == 'normal') {
        cod_rate = +summary.selecteddelivery.cod_rate;
        summary.cod_value = cod_rate;
      }
      summary.cod_value = Math.ceil(summary.cod_value);
      summary.total = summary.total + summary.cod_value;

    } else {
      summary.cod_value = 0;
    }
    summary.total = Math.round(summary.total * 100) / 100;

  }
  
  getCardInfoByOrdercode(ordercode: string | number) {
    return this._http.get(`/order/getCardInfo/${ordercode}`).toPromise()
  }

  getOrderCredit(refNo: any) {
    return this._http.get(`/order/getOrderCredit/${refNo}`).toPromise()
  }

  exportCustom(data: any) {
    return this._http.post('/order/exportCustom', data).toPromise();
  }

}
