import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilService } from './services/util.service';
import { CookieService } from 'ngx-cookie';
import { Etc } from './util/etc';

@Injectable({
  providedIn: "root"
})
export class LangGuard implements CanActivate {
  constructor(
    private _util: UtilService,
    private _cookie: CookieService,
    private _etc: Etc,
    private activatedRoute: ActivatedRoute
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    //if(window["seo"]){ return true; }
    //this.router.config[7].path = ":page/:link"
    //console.log('lang guard', this.router.config);
    //console.log(next)

    this._util.getLangs().then((res:any) =>{

      if(!window['seo']){
        if(!this._cookie.get('langs')){
          this._cookie.put('langs', 'true', {expires: this._etc.cookieTime(60)});
          if(!window["seo"]){
            localStorage.setItem('langs', JSON.stringify(res));
          }
        }
      }else{
        localStorage.setItem('langs', JSON.stringify(res));
      }


      if(res.length > 1){

        var path:string = window.location.pathname;
        var search: number = path.search('/system/');
        if(next.url.length > 0 && search == -1){
          // var lang = localStorage.getItem('lang') || "lang1";
          var lang = this._etc.getCurrentLang();

          var lang_data =  res.find((x:any)=>x.ref == lang);
          //console.log(res);
          var checkPath = res.find((x:any)=>x.name.toLowerCase() == next.url[0].path.toLowerCase());

          if(checkPath && checkPath.name !== lang_data.name){
            // localStorage.setItem('lang', checkPath.ref);
            this._etc.setCurrentLang(checkPath.ref);
            lang_data = checkPath;
          }
          if(!checkPath){

            if(this.activatedRoute.snapshot.queryParamMap.keys.length > 0){
              var _query:any = JSON.stringify(this.activatedRoute.snapshot.queryParamMap)
              _query = JSON.parse(_query)
              //console.log(_query)
              _query = _query.params
             // this.router.navigate([`/${lang_data.name}${decodeURI(window.location.pathname)}`], { queryParams: _query });
            }else{
              //this.router.navigate([`/${lang_data.name}${decodeURI(window.location.pathname)}`]);
            }


          }
        }
      }else{

        var path:string = window.location.pathname;
        var search: number = path.search('/system/');
        if(next.url.length > 0 && search == -1){
          // var lang = localStorage.getItem('lang') || "lang1";
          var lang = this._etc.getCurrentLang();

          var lang_data =  res.find((x:any)=>x.ref == lang);
          //console.log(res);
          var checkPath = res.find((x:any)=>x.name.toLowerCase() == next.url[0].path.toLowerCase());

          if(checkPath && checkPath.name !== lang_data.name){
            // localStorage.setItem('lang', checkPath.ref);
            this._etc.setCurrentLang(checkPath.ref);
            lang_data = checkPath;
          }
          if(checkPath){

            if(this.activatedRoute.snapshot.queryParamMap.keys.length > 0){
              var _query:any = JSON.stringify(this.activatedRoute.snapshot.queryParamMap)
              _query = JSON.parse(_query)
              //console.log(_query)
              _query = _query.params

              var _link = `${decodeURI(window.location.pathname)}`.replace(`/${lang_data.name}/`, "/")
             // this.router.navigate([`${_link}`], { queryParams: _query });
            }else{
              var _link = `${decodeURI(window.location.pathname)}`.replace(`/${lang_data.name}/`, "/")
            //  this.router.navigate([`${_link}`]);
            }


          }
        }


      }


    }).catch(err=>{
      //console.log(err);
    })

    return true;

  }
}
