import { Loader, LoaderOptions } from 'google-maps';

export class Etc{
    google:any;

    orderstatus_img(){
    return {
            '-1': { img: '/assets/images/core-imgs/icon/order-status5.png', name: 'ยกเลิก' },
            '0': { img: '/assets/images/core-imgs/icon/order-status0.png', name: 'Pending'},
            '1': { img: '/assets/images/core-imgs/icon/order-status1.png', name: 'รอชำระเงิน' },
            '2': { img: '/assets/images/core-imgs/icon/order-status2.png', name: 'ได้รับเงิน' },
            '3': { img: '/assets/images/core-imgs/icon/order-status3.png', name: 'จัดเตรียมสินค้า' },
            '4': { img: '/assets/images/core-imgs/icon/order-status4.png', name: 'จัดส่ง' },
        }
    }
    payment_img(){
    return {
            '-2': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'รอชำระเงิน' },
            '-1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
            '1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
            '2': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'โอนผ่านธนาคาร' },
            '3': { img: '/assets/images/core-imgs/icon/payment-credit.png', name: 'เครดิตการ์ด' },
            '5': { img: '/assets/images/core-imgs/icon/payment-qrcode.png', name: 'QR CODE' },
            '4': { img: '/assets/images/core-imgs/icon/payment-cod.png', name: 'เก็บเงินปลายทาง' },
            '6': { img: '/assets/images/core-imgs/icon/payment-crypto.svg', name: 'Crypto currency' },
            '7': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'อื่นๆ' }
        }
    }


    channel_img(){
      return {
        website: { name: "เว็บไซต์", img: "/assets/images/sell-channel/ch-website.jpg" }, 
        "jd-central": { name: "JD Central", img: "/assets/images/sell-channel/ch-jdcentral.jpg" }, 
        lazada: { name: "Lazada",  img: "/assets/images/sell-channel/ch-lazada.jpg" }, 
        line_shopping: { name: "Line Shopping", img: "/assets/images/sell-channel/ch-lineshopping.jpg" }, 
        shopee: { name:  "Shopee", img: "/assets/images/sell-channel/ch-shopee.jpg" },
        tiktok: { name:  "TikTok", img: "/assets/images/sell-channel/ch-tiktokshop.jpg" }
      }
    }




    isBackEnd(){
        let search = location.href.search(`${window.location.origin}/system`);
        // console.log("isbackend", search);
        if(search == -1){
            return false;
        }else{
            return true;
        }
    }

    async initGoogleMap(key){
      try{
        key = key || '-';
        const loader = new Loader(key, {});
        this.google = await loader.load();
      }catch(err){}
    }

    getGoogleMap(){
        return this.google;
    }

    getCurrentLang(){
        let backend = this.isBackEnd();
        let lang = "lang1";

        if(backend){
            lang = localStorage.getItem('lang_backend');
            if(!lang){
                localStorage.setItem('lang_backend','lang1');
            }
            lang = localStorage.getItem('lang_backend');
        }else{
            lang = localStorage.getItem('lang');
            if(!lang){
                lang = 'lang1';
            }
        }
        return lang;
    }

    setCurrentLang(lang){
        let backend = this.isBackEnd();
        if (backend) {
            localStorage.setItem('lang_backend', lang);
        }else{
            localStorage.setItem('lang', lang);
        }
    }


    filter_seo_desc(desc,length = 200){
        if(desc == null){
            return "";
        }
        desc = desc.replace(/<\s*br\/*>/gi, " ");
        desc = desc.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, " ");
        desc = desc.replace(/<\s*\/*.+?>/ig, " ");
        desc = desc.replace(/ {2,}/gi, " ");
        desc = desc.replace(/\n+\s*/gi, " ");
        desc = desc.replace(/&nbsp;/, " ");

        if(desc.length > length){
            desc = desc.substring(0, length) + '...';
        }

        return desc;
    }

    random_string(amount) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < amount; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    uid() {
        var date: any = new Date();
        var year:any = date.getUTCFullYear() - 2000;
        var mon:any = date.getUTCMonth() + 1;
        var day:any = date.getUTCDate();
        var hour:any = date.getUTCHours();
        var min:any = date.getUTCMinutes();
        var sec:any = date.getUTCSeconds();
        var milisec:any = date.getMilliseconds();
        var random = this.random_string(6);
        if (milisec < 10) {
            milisec = "0" + (milisec.toString());
        }
        if (sec < 10) {
            sec = "0" + (sec.toString());
        }
        if (min < 10) {
            min = "0" + (min.toString());
        }
        if (hour < 10) {
            hour = "0" + (hour.toString());
        }
        if (day < 10) {
            day = "0" + (day.toString());
        }
        if (mon < 10) {
            mon = "0" + (mon.toString());
        }
        if (year < 10) {
            year = "0" + (year.toString());
        }
        var numStr:any = year.toString() + mon.toString() + day.toString() + hour.toString() + min.toString() + sec.toString() + milisec.toString();
        var num:any = parseInt(numStr);
        var stkNum:any = num.toString(36);
        stkNum += random;
        return stkNum;
    }

    cookieTime(m:number){
      var date = new Date();
      var minutes = m;
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      return date;
    }

    formatMoney(amount:any){
      return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    getOrderHtml(order:any) {
      console.log(order);
      // country_name
      // country_alpha2_code
      let isShippingInter = false;
      let isBillingInter = false;
      if (order.country_alpha2_code != 'TH'){
        isShippingInter = true;
      } 
      if (order.bill_country_alpha2_code != 'TH') {
        isBillingInter = true;
      }
      if(order.user_id == -1){
        order['customer'] = {
            username: '-'
        }
      }
      var shipping_name = ""
      var shipping_price = "0"
      if (order.freeshipping == 1) {
          shipping_name = "ฟรีค่าขนส่ง / Free"
      }
      if (order.purchase_id && order.freeshipping == 0) {
          shipping_price = order.delicharge
          if (order.delichargeaddon > 0) {
              shipping_price = order.delicharge+order.delichargeaddon
          }
          shipping_name = order.delivery.name
      }

      if (!order.purchase_id && order.freeshipping == 0 && order.user_id !== -1) {
        // console.log(order)
          shipping_price = order.delicharge
        shipping_name = order.delivery.name
      }

      shipping_price = this.formatMoney(shipping_price)

      if(order.user_id == -1){
        shipping_name = "รับหน้าร้าน / Pickup"
      }

      var promotion_state = false
      var promotion_name = ""

      // if (order.discount > 0) {
          order.promotion.forEach(obj => {
           if(obj.promotion_type == 'coupon'){
              promotion_state = true;
             promotion_name = `ส่วนลดคูปอง / Coupon : ${obj.coupon}`
             if (obj.value_type == "percent") {
               promotion_name += ` ( ${obj.value}% )`
             }
           }else if(obj.promotion_type == 'condition'){
              promotion_state = true;
             promotion_name = `ส่วนลดโปรโมชั่น / Promotion : ${obj.title}`
             if (obj.value_type == "percent") {
               promotion_name += ` ( ${obj.value}% )`
             }
           }
          });
      // }
      let _bill_address = "";
      if (!isBillingInter){
        _bill_address = `${order.bill_address1} แขวง/ตำบล ${order.bill_subdistrict} เขต/อำเภอ ${order.bill_district} ${order.bill_province} ${order.bill_zipcode}`;
      }else{
        _bill_address = `${order.bill_address1} ${order.bill_province} ${order.bill_zipcode} ${order.bill_country_name}`;
      }
      let _shipping_address = "";
      if (!isShippingInter) {
        _shipping_address = `${order.shipping_address1} แขวง/ตำบล ${order.shipping_subdistic} เขต/อำเภอ ${order.shipping_district} ${order.shipping_province} ${order.shipping_zipcode}`;
      }else{
        _shipping_address = `${order.shipping_address1} ${order.shipping_province} ${order.shipping_zipcode} ${order.country_name}`;
      }

      var _html = ''
      _html += `<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>พิมพ์ใบสั่งซื้อ</title>
</head>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}
body{
  font-family: sans-serif;
}
p{
  margin-top: 0;
  margin-bottom: 2px;
}
p:last-child{
  margin-bottom: 0;
}

.page-single{
  width: 100%;
  font-size: 14px;
  padding: 15px;
}
.page-double{
  width: 50%;
  float: left;
  font-size: 13px;
  padding: 15px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-6{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.header,
.address,
.product{
  padding: 10px 0;
}
.product{
  margin-bottom: 1rem;
}
.note{
  width:75%;
}
.header{
  font-size: 90%;
  border-bottom: 1px dashed #000;
}
.header:after,
.address:after{
  content: '';
  display: block;
  clear: both;
}
.header_qrcode{
  width: 74px;
  height: 74px;
  float: left;
}
.header_detail{
  width: calc(100% - 74px);
  float: left;
  padding-left: 10px;
}
.header .order-number{
      font-size: 120%;
}
.address{
  font-size: 90%;
}
.table-product{
  width: 100%;
  border-collapse: collapse;
}
.table-product thead tr th{
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
}
.table-product thead tr th,
.table-product tbody tr td{
      padding: 7px;
}
.table-product tfoot tr td{
  padding: 3px 7px;
}
.table-product tbody tr td{
      border-bottom: 1px dotted #d5d5d5;
}
.table-product tfoot .order-total td{
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px 7px;
  font-size: 110%;
}
.table-product .col-product{
  text-align: left;
}
.table-product .col-number,
.table-product .col-qty{
  text-align: center;
  width: 50px;
}
.table-product .col-price{
  text-align: right;
  min-width:75px;
}
.table-product .col-image{
  width: 58px;
  text-align: center;
}
.table-product .product-image{
  width: 44px;
  height: 44px;
  overflow: hidden;
  margin: auto;
}
.table-product .product-image img{
  width: 100%;
  height: auto;
}
.table-product .product-name{
  margin-bottom: 5px;
}
.table-product .product-sku{
  opacity: 0.7;
  font-size: 90%;
}
.p-2 {
  padding: .5rem;
}
.border{
    border: 1px solid #dee2e6;
}
.rounded{
    border-radius: .25rem;
}
.pagebreak {
	clear: both;
	page-break-after: always;
}
</style>

<body>

  <div class="page-single">

      <div class="header">
          <div class="header_qrcode" id="ordercode_${order.ordercode}">
          </div>
          <div class="header_detail">
              <p class="order-number">
                  <strong>คำสั่งซื้อหมายเลข / Order No. ${order.ordercode}</strong>
              </p>
              <div class="row">
                  <div class="col-md-6">
                      วันที่สั่งซื้อ / Order Date : ${order.created_at}
                  </div>
                  <div class="col-md-6">
                      สถานะ / Status : ${order.statusname}
                  </div>
                  <div class="col-md-6">
                      เลขพัสดุ / Track Code : ${order.trackcode || '-'}
                  </div>
                  <div class="col-md-6">
                      ช่องทางการขาย / Channel : ${order.channel}
                  </div>
                  <div class="col-md-6">
                      การชําระเงิน / Payment : ${order.paymenttype.name}
                  </div>
                  <div class="col-md-6">
                      อีเมล์ / Email : ${order.customer.username}
                  </div>
              </div>
              <p>

              </p>
              <p>

              </p>
              <p>

              </p>
          </div>
          </div>`
          if(order.user_id !== -1){
              _html += `
          <div class="address">
              <div class="row">
                  <div class="col-md-6">
                      <p><strong><u>ที่อยู่สำหรับออกบิล / Billing Address</u></strong></p>
                      <p>${order.bill_name} ${order.bill_lastname || ''} (${order.bill_tel})</p>
                      <p>${_bill_address}</p>
                  </div>
                  <div class="col-md-6">
                      <p><strong><u>ที่อยู่สำหรับจัดส่ง / Shipping Address</u></strong></p>
                      <p>${order.shipping_name} (${order.shipping_tel})</p>
                      <p>${_shipping_address}</p>
                  </div>
              </div>
          </div>`

          }
          if(order.user_id == -1){
              _html += `
          <div class="address">
              <div class="row">
                  <div class="col-md-6">
                      <p><strong><u>ที่อยู่สำหรับออกบิล / Billing Address</u></strong></p>
                      <p>${order.bill_name}</p>
                  </div>
                  <div class="col-md-6">
                      <p><strong><u>ที่อยู่สำหรับจัดส่ง / Shipping Address</u></strong></p>
                      <p>${order.shipping_name}</p>
                  </div>
              </div>
          </div>`
          }
          /*
           <th class="col-number">ลำดับ</th>
                      <th class="col-number">รูปสินค้า</th>
                      <th class="col-product">ข้อมูลสินค้า</th>
                      <th class="col-price">ราคาต่อหน่วย</th>
                      <th class="col-qty">จำนวน</th>
                      <th class="col-price">ราคารวม</th>
          */
    _html += `
      <div class="product">
          <table class="table-product">
              <thead>
                  <tr>
                      <th class="col-number">No.</th>
                      <th class="col-number">Image</th>
                      <th class="col-product">Product</th>
                      <th class="col-price">Price</th>
                      <th class="col-qty">Qty</th>
                      <th class="col-price">Total</th>
                  </tr>
              </thead>
              <tbody>
                  `
      var i = 1
      for (let obj of order.details) {
          if (obj.prop_enable == 1) {
              obj.title_lang1 = `${obj.title_lang1} (${obj.properties_desc} - ${obj.property_info})`
          }
          if (obj.prop_enable == 2) {
              obj.title_lang1 = `${obj.title_lang1} (${obj.properties_desc} - ${obj.property_info} | ${obj.properties_desc2} - ${obj.property_info2})`
          }
          _html += `
                <tr>
                  <td class="col-number">${i}</td>
                  <td class="col-image">
                      <figure class="product-image">
                          <img src="${obj.feature_img}">
                      </figure>
                  </td>
                  <td class="col-product">
                      <div class="product-name">${obj.title_lang1}</div>
                      <div class="product-sku">SKU : ${obj.sku}</div>
                  </td>
                  <td class="col-price">
                  ${obj.temp_price?`<s>${obj.temp_price}</s>`:''}
                  ${this.formatMoney(obj.product_price)} ฿
                  </td>
                  <td class="col-qty">${obj.qty}</td>
                  <td class="col-price">${this.formatMoney(obj.totalprice)} ฿</td>
                </tr>

                  `
          i++
      }


      let _out_of_stock = order.free_item.filter(x => x.out_of_stock == 1);
      if (order.free_item.length && (order.free_item.length > _out_of_stock.length)) {
        _html += `<tr class="pb-0">
                <td colspan="6" class="pb-0"><strong>ของแถม</strong></td>
              </tr>`;
        order.free_item.forEach(free => {
          if (free.out_of_stock){return;}
          _html += `
              <tr>
                <td class="col-number text-center">1</td>
                <td class="col-image">
                  <figure class="product-image">
                    <img src="${free.feature_img}">
                  </figure">
                </td>
                <td class="col-product">${free.title_lang1}</td>
                <td class="col-price text-right">0.00 ฿</td>
                <td class="col-qty text-center">${free.qty}</td>
                <td class="col-price text-right">0.00 ฿</td>
              </tr>
               `;
        });
      }
     

     

      _html += `
              </tbody>
              <tfoot>
                  <tr>
                      <td colspan="4" style="text-align:right">ราคารวมสินค้า / Amount</td>
                      <td colspan="2" style="text-align:right">${this.formatMoney(order.amount)} ฿</td>
                  </tr>
                  `
      if (order.discountaddon > 0) {
          _html += `
                      <tr>
                          <td colspan="4" style="text-align:right">ส่วนลดพิเศษ / Discount</td>
                          <td colspan="2" style="text-align:right">-${this.formatMoney(order.discountaddon)} ฿</td>
                      </tr>`
      }
      if (promotion_state) {
          _html += `
                  <tr>
                      <td colspan="4" style="text-align:right">${promotion_name}</td>
                      <td colspan="2" style="text-align:right">-${this.formatMoney(order.discount)} ฿</td>
                  </tr>`
      }

      if(order.vat > 0){
        _html += `
        <tr>
            <td colspan="4" style="text-align:right">Vat ${order.vatrate}%</td>
            <td colspan="2" style="text-align:right">${this.formatMoney(order.vat)} ฿</td>
        </tr>`
      }


     _html += `
                  <tr>
                      <td colspan="4" style="text-align:right">${shipping_name} ( ${Number(order.weight).toFixed(2)} กรัม / g )</td>
                      <td colspan="2" style="text-align:right">${shipping_price} ฿</td>
                  </tr>`;
    if(order.cod){
        _html += `
                  <tr>
                      <td colspan="4" style="text-align:right">ค่าบริการ COD / COD Fee</td>
                      <td colspan="2" style="text-align:right">${Number(order.cod_value).toFixed(2)} ฿</td>
                  </tr>`;
    }

    _html +=    `<tr>
                      <td colspan="4" style="text-align:right">
                          <strong>ราคารวมทั้งหมด / Total</strong></td>
                      <td colspan="2" style="text-align:right">
                          <strong>${this.formatMoney(order.totals)} ฿</strong>
                      </td>
                  </tr>
              </tfoot>
          </table>
      </div>

      <div class="note">
        <div class="row">
            <div class="col-md-6">
                <div class="rounded border p-2">
                <p><strong>หมายเหตุภายใน / Remark : </strong></p>
                <p>${order.note || '-'}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rounded border p-2">
                <p><strong>หมายเหตุจากลูกค้า / Customer Note : </strong></p>
                <p>${order.customer_note || '-'}</p>
                </div>        
            </div>
        </div>
      </div>

      

  </div>
    <div class="pagebreak"></div>
</body>

</html>`
      return _html
  }


  getStyleHeader(data:any, _this:any = null){
    // console.log(data);
    var s =  ``;
    if(data.options.backgroundHeader){
      s = `
      <style>
        #dynamic_components_0 .setting-icon-wrap{
          top: unset!important;
          bottom: 15px!important;
        }
        .header_logo,
        .header_top,
        .header_social{
          background-color: rgba(255, 255, 255, 0)!important;
        }
        app-page-not-found{
          display: block;
          padding-top: 120px;
        }
        ${data.options.backgroundColorMenu?`.header_header{
          background-color: var(--header-backgroundcolor)!important;
        }`: `.header_header{ background-color: rgba(255, 255, 255, 0)!important; }`}
  
  
        .header_mainmenu.sticky{
          box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
        }
  
      </style>
      `;
    }else{
  
      s =  `
        <style>
  
          .header_header{
            background-color: var(--header-backgroundcolor)!important;
          }
  
          .header_header{
            box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
          }
  
        </style>
        `
    }
  
    if(_this && _this.renderType == 'backend'){
      s += `
      <style>
        .header_mainmenu.sticky{
          position: relative!important;
          box-shadow: unset;
        }
  
      </style>
      `
    }
  
  
    var header_template = ''
    if(data.options.header_template == '1' ){
      header_template = `
      @media (min-width: 992px){
  
        ._header_menu:hover,
        ._header_menu.selected,
        ._header_menu:hover > .nav-group,
        ._header_menu:hover > .nav-group a,
        ._header_menu.selected > .nav-group,
        ._header_menu.selected > .nav-group a,
        ._header_menu.hover > .nav-group a{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding:  0;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu > .nav-group{
          padding: 0;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 20px 15px;
        }
        .header_mainmenu .navbar-nav .nav-item .submenu-trigger{
          padding-left: 0;
          padding-right: 15px;
          font-size: 18px;
        }
        .item-example._header_menu,
        .item-example._header_menu > .nav-group a {
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
      }
      `
    }
  
    if(data.options.header_template == '2' ){
      header_template +=`
      @media (min-width: 992px){
        ._header_menu:hover{
          background-color: transparent;
        }
  
        ._header_menu:hover > .nav-group,
        ._header_menu:hover > .nav-group a,
        ._header_menu.selected > .nav-group,
        ._header_menu.selected > .nav-group a,
        ._header_menu.hover > .nav-group a{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding: 10px 5px;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 10px 15px;
        }
        .header_mainmenu .navbar-nav .nav-item .submenu-trigger{
          padding-left: 0;
          padding-right: 15px;
          font-size: 18px;
        }
  
        .item-example._header_menu{
          background-color: transparent;
        }
        .item-example._header_menu > .nav-group,
        .item-example._header_menu > .nav-group a{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
      }
      `
    }
  
    if(data.options.header_template == '3'){
      header_template = `
      @media (min-width: 992px){
        ._header_menu:hover > .nav-group,
        ._header_menu:hover > .nav-group a,
        ._header_menu.selected > .nav-group,
        ._header_menu.selected > .nav-group a,
        ._header_menu.hover > .nav-group a{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
          border-radius: 20px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding: 10px 5px;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 10px 15px;
        }
        .header_mainmenu .navbar-nav .nav-item .submenu-trigger{
          padding-left: 0;
          padding-right: 15px;
          font-size: 18px;
        }
  
        .item-example._header_menu > .nav-group,
        .item-example._header_menu > .nav-group a{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
          border-radius: 20px;
        }
      }
      `
    }
  
    if(data.options.header_template == '4'){
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding: 10px 15px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu > .nav-group{
          padding: 5px 0;
          border-bottom: 3px solid transparent;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 5px 0!important;
        }
        .navbar-expand-lg .navbar-nav .submenu-trigger{
          padding-right: 0!important;
          padding-left: 10px!important;
          font-size: 18px;
        }
  
        .header_mainmenu .navbar-nav > .nav-item:hover > .nav-group,
        .header_mainmenu .navbar-nav > .nav-item.selected > .nav-group{
          border-color: var(--menu-border_hover);
        }
        .item-example._header_menu > .nav-group{
          border-bottom: 3px solid var(--menu-border_hover)!important;
        }
      }
  
  
      `
    }
  
  
    if(data.options.header_template == '5'){
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding: 15px 5px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu > .nav-group{
          padding: 8px 0px;
          border: 2px solid transparent;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 0px 15px!important;
        }
        .header_mainmenu .navbar-nav .nav-item .submenu-trigger{
          padding-left: 0;
          padding-right: 15px;
          font-size: 18px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu:hover > .nav-group,
        .header_mainmenu .navbar-nav > .nav-item._header_menu.selected > .nav-group{
          color: var(--menu-font_hover);
          border-color: var(--menu-border_hover);
        }
  
        .item-example._header_menu > .nav-group{
          color: var(--menu-font_hover);
          border: 2px solid var(--menu-border_hover)!important;
        }
      }
      `
    }
  
    if(data.options.header_template == '6'){
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu .navbar-nav > .nav-item._header_menu{
          padding: 15px 5px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu > .nav-group{
          padding: 8px 0px;
          border: 2px solid transparent;
          border-radius: 20px;
        }
        .navbar-expand-lg .navbar-nav .nav-link{
          padding: 0px 15px!important;
        }
        .header_mainmenu .navbar-nav .nav-item .submenu-trigger{
          padding-left: 0;
          padding-right: 15px;
          font-size: 18px;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu:hover > .nav-group,
        .header_mainmenu .navbar-nav > .nav-item._header_menu.selected > .nav-group{
          color: var(--menu-font_hover);
          border-color: var(--menu-border_hover);
        }
  
        .item-example._header_menu > .nav-group{
          color: var(--menu-font_hover);
          border: 2px solid var(--menu-border_hover)!important;
          border-radius: 20px;
        }
      }
      `
    }
  
    s += `
    <style>
      ${header_template}
  
      .collapse:not(.show) {
        display: none!important;
      }
      .collapse.show{
        display: block!important;
      }
    </style>
    `
    if(_this && _this.header.init.modalopen){
      s += `
      <style>
        ${header_template}
  
        .item-example,
        .nav-item-example{
          display: block!important;
        }
        .nav-item-show{
          display: none!important;
        }
  
      </style>
      `
    }
  
    s = s.replace(/(\r\n|\n|\r)/gm, "");
    s = s.replace(/        /g, '');
  
    return s;
  }

  genStyleColor(theme_color:any, options:any, modal_status:any, advance_css:any, hide_lang_tab:any) {
    try {
      var color = theme_color;

      var s: string = `
        <style>

            :root {
                --bg-primarycolor: ${color.color_primary}1a;
                --primarycolor: ${color.color_primary};
                --primarycolor-e8: ${color.color_primary}e8;
                --primarycolor-b0: ${color.color_primary}b0;
                --primarycolor-1a: ${color.color_primary}1a;
                --button-backgroundcolor: ${color.button.backgroundcolor};
                --button-backgroundcolor-e8: ${color.button.backgroundcolor}e8;
                --button-backgroundcolor-b0: ${color.button.backgroundcolor}b0;
                --button-backgroundcolor-1a: ${color.button.backgroundcolor}1a;
                --button-backgroundcolorhover: ${
                  color.button.backgroundcolorhover
                };
                --button-border: ${color.button.border}px;
                --button-bordercolor: ${color.button.bordercolor};
                --button-bordercolorhover: ${color.button.bordercolorhover};
                --button-borderhover: ${color.button.borderhover}px;
                --button-borderradius: ${color.button.borderradius}px;
                --button-borderradiushover: ${color.button.borderradiushover}px;
                --button-fontcolor: ${color.button.fontcolor};
                --button-fontcolorhover: ${color.button.fontcolorhover};

                --footer-backgroundcolor: ${color.footer.backgroundcolor};
                --footer-font_hover: ${color.footer.font_hover};
                --footer-fontcolor: ${color.footer.fontcolor};

                --header-backgroundcolor: ${color.header.backgroundcolor};
                --header-backgroundcolor_logo: ${
                  color.header.backgroundcolor_logo
                };

                --menu-background_hover: ${color.menu.background_hover};
                --menu-border_hover: ${color.menu.border_hover};
                --menu-font_hover: ${color.menu.font_hover};
                --menu-fontcolor: ${color.menu.fontcolor};

                --product-backgroundcolor: ${color.product.backgroundcolor};
                --product-fontcolor: ${color.product.fontcolor};

                --socialfooter-backgroundcolor: ${
                  color.socialfooter.backgroundcolor
                };
                --socialfooter-fontcolor: ${color.socialfooter.fontcolor};

                --socialheader-backgroundcolor: ${
                  color.socialheader.backgroundcolor
                };

                --submenu-background_hover: ${color.submenu.background_hover};
                --submenu-font_hover: ${color.submenu.font_hover};

                --topbar-backgroundcolor: ${color.topbar.backgroundcolor};
                --topbar-font_hover: ${color.topbar.font_hover};
                --topbar-fontcolor: ${color.topbar.fontcolor};

            }

            .nav-mobile .nav-link{
              color:  ${color.menu.fontcolor}!important;
            }


            .tmt-btn-all{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all:hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .tmt-btn-all:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .btn-outline-tmt{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .btn-outline-tmt:hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .btn-outline-tmt:focus{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .btn-outline-tmt.hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .fr-view .tmt-btn-all{
              background-color: ${color.button.backgroundcolor}!important;
              color:  ${color.button.fontcolor}!important;
              border: solid  ${color.button.border}px!important;
              border-color:  ${color.button.bordercolor}!important;
              border-radius: ${color.button.borderradius}px!important;
            }

            .fr-view .tmt-btn-all:hover,
            .fr-view .tmt-btn-all:focus,
            .fr-view .tmt-btn-all.hover{
              background-color: ${color.button.backgroundcolorhover}!important;
              color:  ${color.button.fontcolorhover}!important;
              border: solid  ${color.button.borderhover}px!important;
              border-color:  ${color.button.bordercolorhover}!important;
              border-radius: ${color.button.borderradiushover}px!important;
            }

            .fr-view .tmt-btn-all:hover span,
            .fr-view .tmt-btn-all:focus span{
              color:  ${color.button.fontcolorhover}!important;
            }



            .color_render_wrap .ant-btn-primary{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-primary:hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }
            .color_render_wrap .ant-btn-default{
              border: solid  ${color.button.border}px;
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-default:hover{
              border: solid  ${color.button.borderhover}px;
              border-radius: ${color.button.borderradiushover}px;
            }

            .color_render_wrap .ant-btn-primary:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .color_render_wrap .ant-btn-primary.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .ant-pagination-item-active,
            .ant-pagination-item:focus,
            .ant-pagination-item:hover {
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus,
            .ant-pagination-item-active:hover{
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus a,
            .ant-pagination-item-active:hover a,
            .ant-pagination-item:focus a,
            .ant-pagination-item:hover a{
              color:  ${color.button.fontcolor};
            }

            .btn_search_color{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            .btn_search_color:hover{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            app-page-product04 .ant-slider-handle,
            app-page-product04 .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
              border-color:  ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider:hover .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }


            #wh-widget-send-button {
              z-index: 99!important;
            }


            .static-bag{
                ${options.btn_cart_status ? "display: inline-block!important;" : "display: none!important;"}
                ${
                  options.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttoncolor};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-bag:hover{
                ${
                  options.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttonhover};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-top{
                ${options.btn_back_to_top_status ? "display: inline-block!important;" : "display: none!important;"}
                ${
                  options.btn_back_to_top_transparent
                    ? `background-color: ${
                        color.ecommerce.backtotopbackgroundcolor
                      };`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-top i{
                color: ${color.ecommerce.backtotopfontcolor};
            }
            .static-top:hover{
                ${
                  options.btn_back_to_top_transparent
                    ? `background-color: ${color.ecommerce.backtotophover};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }


            .item-example,
            .nav-item-example{
              display: ${modal_status? 'block' : 'none'}!important;
            }
            .nav-item-show{
              display: ${modal_status? 'none' : 'block'}!important;
            }

            .static-bag .number-on-bag-float{
              background-color: ${color.ecommerce.cartbuttonbgfontcolor};
              color: ${color.ecommerce.cartbuttonfontcolor};
            }

            ${advance_css}

            .header_mainmenu.sticky{
              background-color: var(--header-backgroundcolor)!important;
              box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
          }

          ${hide_lang_tab? '.header_language{     display: none!important; }' : '' }

        </style>
    `;

      s = s.replace(/(\r\n|\n|\r)/gm, "");
      s = s.replace(/        /g, '');

      return s;
    } catch (err) {
      //console.log(err.message)
      return "";
    }
  }

}
