import { Component, OnInit } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
declare var alasql;
@Component({
  selector: 'ket-product-display-sort',
  templateUrl: './product-display-sort.component.html',
  styleUrls: ['./product-display-sort.component.scss']
})
export class KetProductDisplaySortComponent implements OnInit {

  product_sort: any = [];
  product_sort_display: any = [];
  product_sort_total: number = 0;
  search_model_sort: any = {
    search: "",
    cate_id: "",
    page: 1,
    perpage: 10
  }
  allcategory:any = [];
  max_sort:any = 0;
  constructor(
    private Service_Product:ProductsService,
    private modalService:NzModalService,
    private modalRef:NzModalRef,
    private message:NzMessageService
  ) { }

  async ngOnInit() {
    this.allcategory = await this.Service_Product.productMainCategory();
    this.allcategory.unshift({ id: -1, lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory", search_cate_id:[-1] });
    this.allcategory.unshift({ id: "", lang1: "หมวดหมู่ทั้งหมด", lang2: "All Category", lang3: "All Category", lang4: "All Category", search_cate_id: [] });
    this.getAllProductBySort();
  }

  getAllProductBySort(){
    this.Service_Product.getAllProductBySort().then((res:any)=>{
      console.log(res);
      this.product_sort = res.data;
   
      this.searchProductSort(true);
    }).catch((data:any)=>{
      console.log(data);
    });
  }

  clearfilterProductSort(){
    this.search_model_sort= {
      search: "",
      cate_id: "",
      page: 1,
      perpage: 10
    }
    this.searchProductSort(true);
  }
  sortKeyup(data){
    data.product_sort = `${data.product_sort}`.replace(/^([^0-9]*)$/g,'');
  }

  confirmReplaceDuplicateSort(data){
    return new Promise((resolve,reject)=>{
     
        this.modalService.confirm({
          nzTitle: 'คุณแน่ใจหรือไม่?',
          nzContent: `อันดับสินค้าจะมีการเปลี่ยนแปลงไปแทนที่ 
          <div class="row">
            <div class="col-2">
             <img class="modal-confirm-img" src="${data.feature_img}">
            </div>
            <div class="col-10">
             <span class="modal-confirm-txt">${data.title_lang1}</span>
            </div>
           
           
          </div>`,
          nzOnOk: () => {
            resolve(true);
          },
          nzOnCancel: ()=>{
            resolve(false);
          }
        });
      
    })
  }

  async sortBlur(data){
    let duplicate_sort = this.product_sort.find(x => (x.product_sort !== null && x.product_sort !== "") && ( x.product_sort == data.product_sort) && (x.id != data.id));

    if (duplicate_sort){
      let chk: any = await this.confirmReplaceDuplicateSort(duplicate_sort);
      if(!chk){
        data.product_sort = null;
        return
      }
    }

    this.product_sort.filter(x => x.product_sort == data.product_sort).forEach(obj => {
      obj.product_sort = null;
    });
  
    let original_data = this.product_sort.find(x=>x.id == data.id);
    if (original_data){
      if (data.product_sort === "" || data.product_sort === null || data.product_sort == undefined){
        original_data.product_sort = null;
      }else{
        
        original_data.product_sort = +data.product_sort;
      }
    
      this.searchProductSort();
    }
    // console.log(this.product_sort);
  }

  async searchProductSort(clearpage = false) {
    if (clearpage) {
      this.search_model_sort.page = 1;
    }
    var offset_page = Number(this.search_model_sort.perpage) * (Number(this.search_model_sort.page) - 1);
    let sql = "SELECT * FROM ? WHERE 1=1 ";
    let sql_count = "SELECT COUNT(*) as total FROM ? WHERE 1=1";
    let bindparam = [this.product_sort];
    if (this.search_model_sort.search !== ""){
      sql += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `;
      sql_count += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `;
      bindparam.push(`%${this.search_model_sort.search}%`);
      bindparam.push(`%${this.search_model_sort.search}%`);
    }
    if(this.search_model_sort.cate_id){

      let cate = this.allcategory.find(x => x.id == this.search_model_sort.cate_id);
      if(cate){
        let sub ="";
        let sub_arr = cate.search_cate_id.map(x=> `cate_id = ${x}`);
         sub = sub_arr.join(" OR ");
        sql += `AND ( ${sub} )`;
        sql_count += `AND ( ${sub} )`;
      }
      // bindparam.push
    }
    sql += 'ORDER BY product_sort IS NULL,product_sort,id DESC ';
    sql += `LIMIT ${this.search_model_sort.perpage} OFFSET ${offset_page}`;

    this.product_sort_display = await alasql(sql, bindparam);
    // let max_sort = await alasql('SELECT max(product_sort) as max_sort FROM ?',[this.product_sort]);
    // console.log('max_sort',max_sort[0].max_sort)
    // this.max_sort = max_sort[0].max_sort;
    let rs = await alasql(sql_count, bindparam);
    this.product_sort_total = rs[0].total;
    if (this.product_sort_display.length == 0 && this.search_model_sort.page > 1) {
      this.search_model_sort.page = this.search_model_sort.page - 1;
      this.searchProductSort();
      return;
    }
  }

  pagechageProductSort(page) {
    this.search_model_sort.page = page;
    this.searchProductSort();
  }

  close(){
    this.modalRef.close();
  }

  updateSort(){
    let obj = this.product_sort.filter(x => x.product_sort != "" && x.product_sort != null ).map((x) => { return { id: x.id,product_sort: x.product_sort } })
    // return;
    $('.theme-loader').show();
    this.Service_Product.updateProductSort({data: obj}).then((res:any)=>{
      $('.theme-loader').hide();
      this.message.success("Save Complete",{nzDuration: 4000});
      this.getAllProductBySort();
    }).catch(data => {
      $('.theme-loader').hide();
      this.message.error(`${data.error.message}`,{nzDuration: 4000});
      console.log(data.error.message);
    });
  }

}