import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

let listsectionelementFront: any = {}
let memory_cache: any = {
  elements_json: null,
  sections_json: null,
  premade_section: null,
  headers_json: null,
  header_json: null
}

@Injectable({
  providedIn: 'root'
})
export class FrontsService {

  constructor(
    private _http:HttpClient,
    private _cookie: CookieService
    ) { }

  testSvc(){
    return this._http.get('').toPromise()
  }

  cacheCall(_promise: Observable<any>, key1: string){
    return new Promise((resolve, reject)=>{
      if(memory_cache[key1]){
        return resolve(memory_cache[key1])
      }else{
        _promise.toPromise().then(res=>{
          memory_cache[key1] = res;
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }
    })

  }

  getElements(){
    return this.cacheCall(this._http.get('/assets/default/elements.json'), 'elements_json');
  }

  getPremadeSections() {
    return this.cacheCall(this._http.get('/assets/default/premade-section.json'), 'premade_section');
  }

  getSections(){
    return this.cacheCall(this._http.get('/assets/default/sections.json'), 'sections_json');
  }

  savePage(data:any){
    return this._http.post('/page/save', data).toPromise();
  }

  savePageByProduct(data:any){
    return this._http.post('/product/saveSectionElement', data).toPromise();
  }


  getPageEleAndSection(data:any){
    return this._http.post('/page/listsectionelement', data).toPromise();
  }

  getProductEleAndSection(data:any){
    return this._http.post('/product/productDetail', data).toPromise();
  }

  getPageEleAndSectionFront(data: any, lang: any = 'lang1') {
    return new Promise((resolve, reject) => {
      if (listsectionelementFront[`${data.type}_${data.page}_${lang}`]) {
        console.log("getPageEleAndSectionFront CASE 1")
        resolve(listsectionelementFront[`${data.type}_${data.page}_${lang}`])
      } else {
        console.log("getPageEleAndSectionFront CASE 2")
        this._http.post('/page/listsectionelementFront', data).toPromise().then(res => {
          listsectionelementFront[`${data.type}_${data.page}_${lang}`] = res;
          // console.log(listsectionelementFront);
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      }
    })
  }

  getHeadersList(){
    return this.cacheCall(this._http.get('/assets/default/headers.json'), 'headers_json');
  }

  getElementForCustomLayout(){
    return this._http.get('/assets/default/custom-layout.json').toPromise();
  }

  getElementForFooter(){
    return this._http.get('/assets/default/footer-elements.json').toPromise();
  }


  getHeaders(){
    return this.cacheCall(this._http.get('/assets/default/header.json'), 'header_json');
    // this._http.get('/assets/default/header.json').toPromise();
  }

  addToDisplay(obj){
    return this._http.post('/page/addToDisplay', obj).toPromise();
  }
  addToPageList(obj){
    return this._http.post('/page/addToPageList', obj).toPromise();
  }
  updateMenuEnable(obj){
    return this._http.post('/page/updateMenuEnable', obj).toPromise();
  }
  updateMenuName(obj) {
    return this._http.post('/page/updateMenuName', obj).toPromise();
  }
  createMenu(obj) {
    return this._http.post('/page/menu', obj).toPromise();
  }
  deleteMenu(obj){
    return this._http.delete('/page/menu/'+obj.page_id).toPromise();
  }
  updateMenuSort(obj){
    return this._http.post('/page/updateMenuSort',obj).toPromise();
  }
  getMenuBackEnd(){
    return this._http.get('/page/menuBack').toPromise();
  }
  getMenuPageList() {
    return this._http.get('/page/menuPageList').toPromise();
  }
  getMenuForCanonical() {
    return this._http.get('/page/menu_for_canonical').toPromise();
  }
  getMenus(){

    if(this._cookie.get('pages')){
      console.log('PAGE USE COOKIE');
      return new Promise((resolve, reject)=>{
        try{
          resolve(JSON.parse(localStorage.getItem('pages')))
        }catch(err){
          this._cookie.remove('pages');
          reject(err);
        }
      })
    }else{
      console.log('PAGE USE Service');
      return this._http.get('/page/menuFront').toPromise();
    }
  }

}
