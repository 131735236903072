import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { cloneDeep } from 'lodash';
import { ExtensionService } from '@cms/services/extension.service';
import { ShopeeService } from '@cms/services/shopee.service';

declare var alasql: any

@Component({
    selector: 'app-modal-get-shopee-order',
    templateUrl: './modal-get-shopee-order.component.html',
    styleUrls: ['./modal-get-shopee-order.component.css']
})
export class ModalGetShopeeOrderComponent implements OnInit {
    @Input() data: any;

    constructor(
        private Service_Shopee: ShopeeService,
        private modalService: NzModalService,
        private modalRef: NzModalRef
    ) { }

    validateOrderId: boolean = false

    model: any = {
        ordersn: ""
    }
    type: any;
    shopee_shop: any = [];
    shop_id: any = null;

    async ngOnInit() {
        this.type = this.data.type;
        this.shopee_shop = await this.Service_Shopee.listShop();
        if (this.shopee_shop[0]) {
            this.shop_id = this.shopee_shop[0].shop_id;
        }
    }

    ok() {

        console.log(this.model.ordersn);
        if (!this.model.ordersn && this.type == 'byID') {
            this.validateOrderId = true
            return false;
        }
        if (!this.shop_id) {
            this.modalService.error({
                nzTitle: "กรุณาเลือกร้านค้า"
            });
            return false;
        }
        $('.theme-loader').fadeIn();
        // this.model.shop_id = this.shop_id;
        let obj = {
            shop_id: this.shop_id,
            order_sn_list: [this.model.ordersn]
        }
        if (this.type == 'byID') {
            this.Service_Shopee.syncOrderByOrderNumber(obj).then((res: any) => {
                $('.theme-loader').fadeOut();
                this.modalService.success({
                    nzTitle: 'อยู่ระหว่างดำเนินการดึงข้อมูล จาก Shopee',
                    nzContent: 'ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก Shopee ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที',
                    nzOkText: 'ตกลง',
                    nzWrapClassName: 'vertical-center-modal',
                });
                this.modalRef.close(true);
            }).catch((data) => {
                $('.theme-loader').fadeOut();
                this.modalService.error({
                    nzTitle: data.error.message
                });
            });
        }



        return false;
    }

    close() {
        this.modalRef.close(true);
    }
}