import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd/ng-zorro-antd.module';
import { ColorPickerModule } from 'ngx-color-picker';
// import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FacebookModule } from 'ngx-facebook';
import { InfiniteScrollModule } from './../libs/ngx-infinite-scroll/ngx-infinite-scroll';

import { limitStringPipe } from '../pipes/limit-string.pipe';
import { removeTagPipe } from '../pipes/tmt-remove-tag.pipe';
// import { FilemanagerComponent } from '@cms/components/filemanager';
// import { ResponsiveFileDirective } from '../dirctives/responsive_file.directive';
// import { VenoboxDirective } from '../dirctives/venobox.directive';
import { FroalaEditorModule, FroalaViewModule } from '../libs/froala/froala';

import { pathLangPipe } from '../pipes/path-lang.pipe';
import { NestableModule } from '@ketshopweb/ngx-nestable';
import { SelectCustomerComponent } from '../components/share/select-customer';
import { FontDirective } from '../dirctives/font.directive';
import { LangsPipe } from '../pipes/langs.pipe';

import { resizeAbleColumnDirective } from '../dirctives/resize-able-column.directive';
import { dateFormatPipe } from '../pipes/date-format.pipe';
import { TranslateDirective } from '../dirctives/translate.directive';
import { PermissionDirective }  from '../dirctives/permission.directive';
import { GoogleMapComponent } from '../components/share/google-map/google-map.component';
import { SelectProvinceComponent } from '../components/share/select-province';
import { SelectDistrictComponent } from '../components/share/select-district';
import { SelectSubDistrictComponent } from '../components/share/select-subdistrict';
import { SelectLangsComponent } from '../components/share/select-langs';
import { SelectFontsComponent } from '../components/share/select-fonts/select-fonts.component';
import { CookieModule } from 'ngx-cookie';
import {NgxMaskModule, IConfig} from 'ngx-mask'
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SortablejsModule } from 'angular-sortablejs'
import { ReportTeamComponent } from '@cms/system/report-team/report-team.component';
import { ReportTeamSaleComponent } from '@cms/system/report-team/report-team-sale/report-team-sale.component';
import { ReportTeamWarehouseComponent } from '@cms/system/report-team/report-team-warehouse/report-team-warehouse.component';
import { FeatureLandingComponent } from '@cms/components/feature-landing/feature-landing.component';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { TmtFilterPipe } from '../pipes/tmt-filter.pipe';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SendToPeakComponent } from '../system/modules/shared-order/send-to-peak/send-to-peak.component';
import { PeakComponent } from '@cms/system/extension/peak/peak.component';
import { TmtGenStyleDirective } from '../dirctives/genstyle.directive';
import { HashDataDomPipe } from '../pipes/hash-data-dom.pipe';

import { NumberDirective } from '../dirctives/numberonly.directive';
import { LazyLoadDirective } from '../dirctives/lazy-load.directive';
import { SanitizeHtmlModule } from '@cms/pipes/sanitize-html';
import { KetFilemanagerModule } from '@ketshopweb/ui/filemanager';
import { KetResponsiveFileModule } from '@ketshopweb/ui/responsive-file';
import { KetVenoboxModule } from '@ketshopweb/ui/venobox';
import { RederStyleModule } from '@cms/components/reder-style/reder-style.module';
import { KetProductFilterModule } from '@ketshopweb/ui/product-filter';
import { NzRateModule } from 'ng-zorro-antd/rate';
// import { TrimDirective } from '@cms/dirctives/trim.directive';
import { KetJoinModule } from '@ketshopweb/ui/join';
import { KetTrimModule } from '@ketshopweb/ui/trim';


var _entryComponents = [
  SendToPeakComponent,
  PeakComponent
]

var _pipes = [
  // SanitizeHtmlPipe,
  limitStringPipe,
  removeTagPipe,
  pathLangPipe,
  dateFormatPipe,
  TmtFilterPipe,
  HashDataDomPipe
]

var _directives = [
  // ResponsiveFileDirective,
  // VenoboxDirective,
  FontDirective,
  LangsPipe,
  resizeAbleColumnDirective,
  TranslateDirective,
  PermissionDirective,
  TmtGenStyleDirective,
  NumberDirective,
  LazyLoadDirective,
  // TrimDirective
]

var _Components = [
  ..._pipes,
  ..._entryComponents,
  ..._directives,
  // FilemanagerComponent,
  SelectCustomerComponent,
  GoogleMapComponent,
  SelectProvinceComponent,
  SelectDistrictComponent,
  SelectSubDistrictComponent,
  FeatureLandingComponent,
  SelectLangsComponent,
  SelectFontsComponent,
  ReportTeamComponent,
  ReportTeamWarehouseComponent,
  ReportTeamSaleComponent
]

var _modules = [
  NestableModule,
  ColorPickerModule,
  NgZorroAntdModule,
  ReactiveFormsModule,
  FormsModule,
  ScrollingModule,
  DragDropModule,
  AngularFireDatabaseModule,
  AngularFireAuthModule,
  InfiniteScrollModule,
  KetResponsiveFileModule,
  KetVenoboxModule,
  KetFilemanagerModule,
  SanitizeHtmlModule,
  RederStyleModule,
  KetProductFilterModule,
  NzRateModule,
  KetJoinModule,
  KetTrimModule,
]

// var _options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    ..._Components
  ],
  entryComponents:[
    ..._entryComponents
  ],
  imports: [
    CommonModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FacebookModule.forRoot(),
    CookieModule.withOptions(),
    NgxMaskModule.forRoot(),
    Ng2GoogleChartsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    ..._modules
  ],
  providers:[
    { provide: NZ_I18N, useValue: en_US },
    pathLangPipe,
    LangsPipe
  ],
  exports: [
    ..._Components,
    ..._modules,
    FroalaEditorModule,
    FroalaViewModule,
    FacebookModule,
    CookieModule,
    NgxMaskModule,
    SortablejsModule,
    Ng2GoogleChartsModule,
  ]
})
export class SharedModule { }
