import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Etc } from '../util/etc';
@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data:any = [];
  _subscribe:any = null
  constructor(
    private _http:HttpClient,
    private store: Store<{ app_store: any }>,
    private _etc: Etc
  ) {
      //console.log('=============   Constructor translate ===================');
      if(!this._subscribe){
        this._subscribe = this.store.pipe(select('app_store', 'LANG_FORMAT')).subscribe((res: any[]) => {
         // console.log(res);
            this.data = res;
        });
      }
   }

    translate(text,from,type = 'text'){
        // console.log('=============    translate ===================');
        // let lang = localStorage.getItem('lang') || 'lang1';
        let lang = this._etc.getCurrentLang();
        let result = this.data.find(x=> `${x.default}`.trim() == `${text}`.trim() && x.from == from );
        //console.log(text, lang, result)
        if(result == "undefined"){
          result = undefined
        }
        if (!result){
            let obj = {
                type: type,
                from: from || 'other',
                text: text
            }

            this.data.push({
              default: `${text}`.trim(),
              from: from,
              lang1: `${text}`.trim(),
              lang2: `${text}`.trim(),
              lang3: `${text}`.trim(),
              lang4: `${text}`.trim()
            })

            this._http.post('/util/langFormat', obj).toPromise().then((resp:any)=>{

            }).catch((err:any)=>{

            });
            
            // console.log(text)
            return text;
        }
       // console.log(result[lang])
        return result[lang];
    }

    thaiUnicodeDecoder(sentence: string) {
      let uniArr = this.thaiUnicodeDetector(sentence);
      if (uniArr.length === 0) return sentence;
      let converted = sentence;
      for (let i in uniArr) {
        converted = converted.replace(uniArr[i], this.unicodeToChar(uniArr[i]));
      }
      return converted;
    }
    
    thaiUnicodeDetector(sentence: string) {
      const regex = /(\u0e[0-9a-f]{2})/g;
      const found = [...sentence.match(regex)];
      return found ? found : [];
    }
    
    unicodeToChar(text) {
      return text.replace(/u[\dA-F]{4}/gi,
        function (match) {
          return String.fromCharCode(parseInt(match.replace(/u/g, ''), 16));
        });
    }
   

}
