import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export namespace IPeakAccount {

  export interface SyncProductState {
    "in_progress": boolean
    "total_new_product": number
    "total_update_product": number
    "total_no_match": number
    "total_api_error": number
    "total_inprogress": number
    "sync_percent": number
    "total_sync": number
    "last_update"?: string
    "no_match_ids": any[]
    "api_error_ids": any[]
  }

  export interface SyncContactState {
    "in_progress": boolean
    "total_new": number
    "total_update": number
    "total_no_match": number
    "total_api_error": number
    "total_inprogress": number
    "sync_percent": number
    "total_sync": number
    "last_update"?: string
    "no_match_ids": any[]
    "api_error_ids": any[]
  }

  export interface AccountsEntity {
    'api_key'?: string | null;
    'created_at'?: string | null;
    'cus_id'?: number | null;
    'expired_date_time'?: string | null;
    'id'?: number;
    'is_tax'?: number | null;
    'is_user_token_enable'?: number | null;
    'name'?: string | null;
    'option': 1 | 2 | 3
    'option_cod': 'include' | 'exclude';
    'status'?: number | null;
    'updated_at'?: string | null;
  }
  

  export interface InputSearchProduct {
    page: number
    per_page: number
    search?: string
    sort?: string
    sort_by?: string
    ids?: string[]
  }

  export interface ProductsEntity {
    'carrying_balance_amount'?: number | null;
    'carrying_balance_value'?: number | null;
    'code'?: string | null;
    'cus_id'?: number | null;
    'description'?: string | null;
    'id'?: number;
    'ket_id'?: number | null;
    'ket_sku'?: string | null;
    'name'?: string | null;
    'peak_id'?: string | null;
    'purchase_account'?: number | null;
    'purchase_value'?: number | null;
    'purchase_vat_type'?: number | null;
    'remaining_balance_amount'?: number | null;
    'sell_account'?: number | null;
    'sell_value'?: number | null;
    'sell_vat_type'?: number | null;
    'type'?: number | null;
    'activity_method'?: string | null;
  }

  export interface ContactsEntity {
    'address'?: string | null;
    'branch_code'?: string | null;
    'peak_id'?: string | null;
    'ket_id'?: string | null;
    'call_center_number'?: string | null;
    'code'?: string | null;
    'contact_email'?: string | null;
    'contact_first_name'?: string | null;
    'contact_last_name'?: string | null;
    'contact_nick_name'?: string | null;
    'contact_phone_number'?: string | null;
    'contact_position'?: string | null;
    'country'?: string | null;
    'created_at'?: string | null;
    'cus_id'?: number | null;
    'district'?: string | null;
    'email'?: string | null;
    'fax_number'?: string | null;
    'id'?: number;
    'name'?: string | null;
    'postcode'?: string | null;
    'province'?: string | null;
    'subdistrict'?: string | null;
    'tax_number'?: string | null;
    'type'?: number | null;
    'updated_at'?: string | null;
    'website'?: string | null;
  }

  export interface QuotationsEntity {
    'code'?: string | null;
    'contact_id'?: string | null;
    'created_at'?: string | null;
    'cus_id'?: number | null;
    'id'?: number;
    'issued_date'?: string | null;
    'ket_ordercode'?: string | null;
    'net_amount'?: number | null;
    'online_view_link'?: string | null;
    'peak_id'?: string | null;
    'peak_response'?: string | null;
    'peak_status'?: number | null;
    'status'?: string | null;
    'updated_at'?: string | null;
    'is_multiple_contact': number;
    'ket_orderdate'?: string;
  }

  export interface SearchContacts {
    page: number
    per_page: number
    search: string
    sort: string
    sort_by: string
    ids: string[]
  }

  type QuotationStatusType = 'Error' | 'AwaitApproval'

  export interface SearchQuotations {
    page: number
    per_page: number
    peak_code: string
    ket_ordercode: string
    status: string | number
    start_date: string
    end_date: string
  }

  export interface ContactListItem {
    id: string
    name: string
    code: string
    type: number
    address: string
    subDistrict: string
    district: string
    province: string
    country: string
    postCode: string
    email: string
    resCode: string
    resDesc: string
  }

  export interface SearchInvoices {
    page: number
    per_page: number
    peak_code: string
    ket_ordercode: string
    status: string | number
    start_date: string
    end_date: string
  }

  export interface SearchReceipts {
      page: number
      per_page: number
      peak_code: string
      ket_ordercode: string
      status: string | number
      start_date: string
      end_date: string
  }

  export interface InvoicesEntity {
    'code'?: string | null;
    'contact_id'?: string | null;
    'created_at'?: string | null;
    'cus_id'?: number | null;
    'id'?: number;
    'issued_date'?: string | null;
    'ket_ordercode'?: string | null;
    'net_amount'?: number | null;
    'online_view_link'?: string | null;
    'peak_id'?: string | null;
    'peak_response'?: string | null;
    'peak_status'?: number | null;
    'reference_code'?: string | null;
    'reference_id'?: string | null;
    'reference_type'?: string | null;
    'status'?: string | null;
    'updated_at'?: string | null;
    'ket_orderdate'?: string;
  }
  
  export interface ReceiptsEntity {
    'code'?: string | null;
    'contact_id'?: string | null;
    'created_at'?: string | null;
    'cus_id'?: number | null;
    'id'?: number;
    'issued_date'?: string | null;
    'ket_ordercode'?: string | null;
    'net_amount'?: number | null;
    'online_view_link'?: string | null;
    'peak_id'?: string | null;
    'peak_response'?: string | null;
    'peak_status'?: number | null;
    'reference_code'?: string | null;
    'reference_id'?: string | null;
    'reference_type'?: string | null;
    'status'?: string | null;
    'updated_at'?: string | null;
    'ket_orderdate'?: string;
  }
  
  export interface OrdersCmsEntity {
  
    /** ค่าขนส่ง ส่วนเกิน เก็บกับเจ้าของ web */
    'add_delicharge'?: number | null;
    'address1': string | null;
    
    /** sending */
    'address2': string | null;
    'amount'?: number | null;
    'amount_receive': number | null;
    'bankname': string | null;
    'banknote': string | null;
    'bill_address1': string | null;
    'bill_address2': string | null;
    'bill_country_alpha2_code': string | null;
    'bill_country_name': string | null;
    'bill_district': string | null;
    'bill_lastname': string | null;
    'bill_name': string | null;
    'bill_province': string | null;
    'bill_subdistrict': string | null;
    'bill_tel': string | null;
    'bill_zipcode': string | null;
    'box_name': string | null;
    'box_value': string | null;
    'card_info': string | null;
    
    /** log change status */
    'change_status': string | null;
    
    /** order channel */
    'channel'?: string | null;
    
    /** line_shopping &#x3D; shop id */
    'channel_id': string | null;
    'channel_name': string | null;
    'channel_ordercode': string | null;
    'channel_shipment': string | null;
    'cod'?: number | null;
    'cod_rate'?: number | null;
    'cod_rate_type': string | null;
    'cod_value'?: number | null;
    'country_alpha2_code': string | null;
    'country_name': string | null;
    'coupon_code': string | null;
    'created_at': string | null;
    'created_by': string | null;
    
    /**  F &#x3D; Credit Card Full Payment I &#x3D; Credit Card Installment */
    'creditpaytype'?: string | null;
    'customer_note': string | null;
    'delicharge'?: number | null;
    'delichargeaddon'?: number | null;
    'delivered_at': string | null;
    'delivered_by': string | null;
    'delivery': string | null;
    'delivery_print'?: number | null;
    'delivery_send_end_time': string | null;
    'delivery_send_start_time': string | null;
    
    /** pickup, dropoff */
    'delivery_service_type': string | null;
    
    /** ได้รับเงินโอนจากขนส่งแล้ว */
    'delivery_transfer_received'?: number | null;
    'delivery_type': string | null;
    'discount'?: number | null;
    'discountaddon'?: number | null;
    'discount_point'?: number | null;
    
    /** sending */
    'district': string | null;
    'freeshipping'?: number | null;
    'freeshipping_manual': string | null;
    'frm_table': string | null;
    'frmtb_id': number | null;
    'id'?: number;
    'name': string | null;
    'note': string | null;
    'ordercode': string | null;
    'order_complete'?: number | null;
    'order_date': string;
    'payment': string | null;
    
    /** 1.สด  2.โอน  3.เครดิต(Omise) */
    'paymenttype': any | null;
    'prepared_at': string | null;
    'prepared_by': string | null;
    'promotion_data': string | null;
    'promotion_id': number | null;
    'promotion_type': string | null;
    'province': string | null;
    'purchase_id': string | null;
    'qutation_id': number | null;
    
    /** 0 normal    1 confirm  -1 cancel  -2 expire 15 day */
    'shipconfirm'?: string | null;
    
    /** วันที่ confirm  */
    'shipconfirm_date': string | null;
    'shipping_group_id': number | null;
    
    /** รหัส order popship */
    'shippingorder': string | null;
    'shippop': string | null;
    'shippopbfdata': string | null;
    'shippopdata': string | null;
    'shippop_status'?: string | null;
    'status'?: number;
    
    /** sending */
    'subdistrict': string | null;
    
    /** เลขนิติบุคคล */
    'tax_no': string | null;
    'tel': string | null;
    
    /** total  &#x3D; price  + vat + delicharge */
    'totals'?: number;
    
    /** EMS Tracking code */
    'trackcode': string | null;
    'transaction_no': string | null;
    'updated_at': string | null;
    'updated_by': string | null;
    'use_point'?: number | null;
    'user_id': number;
    'vat'?: number;
    'vatrate': number | null;
    
    /** 1. excutevat 2 includevat */
    'vattype'?: number | null;
    'weight': number | null;
    'zipcode': string | null;
    'company_id': number | null;
    'company_type': number | null
  }
}

@Injectable({
  providedIn: 'root'
})
export class PeakAccountService {

  constructor(
    private _http: HttpClient
  ) { }

  syncProductsState() {
    return this._http.get('/services/peak-account/products/sync_products_state').toPromise() as Promise<IPeakAccount.SyncProductState>;
  }

  syncProducts() {
    return this._http.get('/services/peak-account/products/sync_products').toPromise() as Promise<IPeakAccount.SyncProductState>;
  }

  accountInfo() {
    return this._http.get('/services/peak-account/accounts/info').toPromise() as Promise<IPeakAccount.AccountsEntity>;
  }

  // sync_info
  syncInfo() {
    return this._http.get('/services/peak-account/products/sync_info').toPromise() as Promise<{
      account: IPeakAccount.AccountsEntity;
      product_total: number;
      product_connected: number;
    }>;
  }

  search(model: IPeakAccount.InputSearchProduct) {
    return this._http.post('/services/peak-account/products/search', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.ProductsEntity[];
    }>;
  }

  syncContactState() {
    return this._http.get('/services/peak-account/contacts/sync_state').toPromise() as Promise<IPeakAccount.SyncContactState>;
  }

  syncContacts() {
    return this._http.get('/services/peak-account/contacts/sync_contacts').toPromise() as Promise<IPeakAccount.SyncContactState>;
  }

  syncContactsInfo() {
    return this._http.get('/services/peak-account/contacts/sync_info').toPromise() as Promise<{
      account: IPeakAccount.AccountsEntity;
      contact_total: number;
      contact_connected: number;
    }>;
  }

  searchContacts(model: IPeakAccount.SearchContacts) {
    return this._http.post('/services/peak-account/contacts/search', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.ContactsEntity[];
    }>;
  }

  searchQuotations(model: IPeakAccount.SearchQuotations) {
    return this._http.post('/services/peak-account/quotations/search', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.QuotationsEntity[];
    }>;
  }

  searchInvoices(model: IPeakAccount.SearchQuotations) {
    return this._http.post('/services/peak-account/invoices/search', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.InvoicesEntity[];
    }>;
  }

  searchReceipts(model: IPeakAccount.SearchQuotations) {
    return this._http.post('/services/peak-account/receipts/search', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.ReceiptsEntity[];
    }>;
  }

  getPeakContactByKetshopwebOrdercode(ordercode: string) {
    return this._http.get(`/services/peak-account/contacts/peak_contact_by_ketshopweb_ordercode/${ordercode}`)
      .toPromise() as Promise<IPeakAccount.ContactListItem[]>;
  }

  quotationSelectPeakContact(model: {
    ket_ordercode: string,
    peak_contact_id: string
  }){
    return this._http.post('/services/peak-account/quotations/select_peak_contact', model).toPromise() as Promise<any>;
  }

  // create_quotation_by_ketshopweb
  createQuotationByKetshopweb(ket_ordercode: string) {
    return this._http.post(`/services/peak-account/quotations/create_quotation_by_ketshopweb`,{ket_ordercode}).toPromise() as Promise<any>;
  }

  createInvoiceByQuotation(quotation_id: string) {
    return this._http.post(`/services/peak-account/invoices/create_invoice_by_quotation`,{quotation_id}).toPromise() as Promise<any>;
  }

  createReceiptByInvoice(invoice_id: string) {
    return this._http.post(`/services/peak-account/receipts/create_receipt_by_invoice`,{invoice_id}).toPromise() as Promise<any>;
  }

  createReceiptByKetshopweb(ordercodes: string[]) {
    return this._http.post(`/services/peak-account/receipts/create_receipt_by_ketshopweb`,{ordercodes}).toPromise() as Promise<any>;
  }

  // connectAndDisconnect
  connectAndDisconnect(model: {
    is_tax: boolean,
    option_cod: 'include' | 'exclude',
    option: 1 | 2 | 3,
    client_token: string
    status: number
  }){
    return this._http.post('/services/peak-account/accounts/connect_and_disconnect', model).toPromise() as Promise<{
      message: string
    }>;
  }

  // searchOrderSelect
  searchOrderSelect(model: {
    page: number
    per_page: number
    ordercode: string
    start_date: string
    end_date: string
  }){
    return this._http.post('/services/peak-account/orders/search_order_select', model).toPromise() as Promise<{
      count: number;
      data: IPeakAccount.OrdersCmsEntity[];
    }>;
  }

  // selectKetshopwebOrderToPeakAsReceipt
  selectKetshopwebOrderToPeakAsReceipt(model: {
    ordercode: string[]
  }){
    return this._http.post('/services/peak-account/orders/select_ketshopweb_order_to_peak_as_receipt', model).toPromise() as Promise<{
      message: string
    }>;
  }

  // map_product_ketshopweb
  mapProductKetshopweb(model: {
    peak_id: string,
    ketshopweb_id: number
  }){
    return this._http.post('/services/peak-account/products/map_product_ketshopweb', model).toPromise() as Promise<{
      message: string
    }>;
  }

  unmapProductKetshopweb(model: {
    peak_id: string
  }){
    return this._http.put('/services/peak-account/products/unmap_product_ketshopweb', model).toPromise() as Promise<{
      message: string
    }>
  }

}
