import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { apiError } from '@cms/interface/interface';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { UtilService } from '@cms/services/util.service';
import { CustomerService } from '@cms/services/customer.service';
import { cloneDeep } from "lodash";
import { DeliveryService } from '@cms/services/delivery.service';
import { FeatureService } from '@cms/services/feature.service';
import { SettingService } from '@cms/services/setting.service';

@Component({
  selector: 'ket-modal-edit-customer',
  templateUrl: './modal-edit-customer.component.html',
  styleUrls: ['./modal-edit-customer.component.scss']
})
export class KetModalEditCustomerComponent implements OnInit {

    page_name: string = "member-profile";
    
    @Input() data: any
    @Input() modal_edit: boolean;
    @Input() renderType: string = 'backend';

    @Output() event_callback = new EventEmitter();

    ProfileForm: FormGroup
    provinceList: any[] = [];
    District: any[] = [];
    SubDistrict: any[] = [];
    countries: {country_name: string, country_alpha2_code: string}[] = []
    feature_shipping_inter: boolean = false

    model = {
        country_name: "Thailand",
        country_alpha2_code: "TH"
    }

    render: boolean = false
    address_layout = 1
    customer_type: string = "member"

    constructor(
        private fb: FormBuilder,
        private modal: NzModalRef,
        private _utilSvc: UtilService,
        private _customerSvc: CustomerService,
        private Service_Delivery: DeliveryService,
        private Service_Feature: FeatureService,
        private settingService: SettingService,
        private modalService: NzModalService
    ) { }

    async ngOnInit(){
        this.render = false
        console.log('this.data', this.data)
        this._utilSvc.getProvince().then((res:any[]) =>{
            this.provinceList = res
            //console.log(res)
        })
        this.feature_shipping_inter = await this.Service_Feature.getFeature("SHIPPING_INTER");
        
        if (this.feature_shipping_inter) {
            this.countries = await this.Service_Delivery.getAvailbleCountry()
        } else if (this.data.country_alpha2_code && this.data.country_alpha2_code != 'TH') {
            this.countries = [
                {
                    country_name: "Thailand",
                    country_alpha2_code: "TH"
                },
                {
                    country_name: this.data.country_name,
                    country_alpha2_code: this.data.country_alpha2_code
                }
            ]
        } else {
            this.countries = [
                {
                    country_name: "Thailand",
                    country_alpha2_code: "TH"
                }
            ]
        }
      
        
        this.onSearchDistrict()
        this.onSearchSubDistrict()

        if(this.data.id !== "new"){
            let validator_email = [];
            if(this.data.type == 'member'){
                validator_email = [Validators.email, Validators.required];
            }
            this.ProfileForm = this.fb.group({
                type: new FormControl({value: this.data.type, disabled: false}),
                address1: new FormControl({value: this.data.address1, disabled: false}, Validators.required),
                district: new FormControl({value: this.data.district, disabled: false}, Validators.required),
                lastname: new FormControl({value: this.data.lastname, disabled: false}),
                name: new FormControl({value: this.data.name, disabled: false}, Validators.required),
                province: new FormControl({value: this.data.province, disabled: false}, Validators.required),
                status: new FormControl({value: this.data.status, disabled: false}, Validators.required),
                subdistrict: new FormControl({value: this.data.subdistrict, disabled: false}, Validators.required),
                tel: new FormControl({value: this.data.tel, disabled: false}, Validators.required),
                username: new FormControl({value: this.data.username, disabled: false}, validator_email),
                zipcode: new FormControl({value: this.data.zipcode, disabled: false}, Validators.required),
                country_name: new FormControl({value: this.data.country_name, disabled: false}, Validators.required),
                tax_no: new FormControl({value: this.data.tax_no, disabled: false})
            })
            this.model.country_name = this.data.country_name
            this.model.country_alpha2_code = this.data.country_alpha2_code
        }else{
            this.ProfileForm = this.fb.group({
                type: new FormControl({value: 'member', disabled: false}),
                address1: new FormControl({value: '', disabled: false}, Validators.required),
                district: new FormControl({value: '', disabled: false}, Validators.required),
                lastname: new FormControl({value: '', disabled: false}),
                name: new FormControl({value: '', disabled: false}, Validators.required),
                province: new FormControl({value:'กรุงเทพมหานคร', disabled: false}, Validators.required),
                status: new FormControl({value: 1, disabled: true}, Validators.required),
                subdistrict: new FormControl({value: '', disabled: false}, Validators.required),
                tel: new FormControl({value: '', disabled: false}, Validators.required),
                username: new FormControl({value: '', disabled: false}, [Validators.email, Validators.required]),
                zipcode: new FormControl({value: '', disabled: false}, Validators.required),
                country_name: new FormControl({value: 'Thailand', disabled: false}, Validators.required),
                tax_no: new FormControl({value: '', disabled: false})
            })
        }
        this.settingService.searchOption({
            pop_key: ['address_layout']
          }).then((res: any[]) => {
            for(let obj of res){
              if(obj.pop_key == 'address_layout') {
                if(!obj.pop_value) obj.pop_value = '1'
                this.address_layout = parseInt(obj.pop_value)
                if(this.address_layout == 2){
                    this.ProfileForm.get('province').disable()
                    this.ProfileForm.get('district').disable()
                    this.ProfileForm.get('subdistrict').disable()
                    this.ProfileForm.get('zipcode').disable()
                }
              }
            }
          })
        this.render = true
    }


    onMemberTypeChange(event: string){
        console.log('onMemberTypeChange', event)
        let key = 'username'
        if(this.ProfileForm.get('type').value == 'guest'){
            // this.ProfileForm.controls['username'].reset()
            let value = this.ProfileForm.controls[key].value
            this.ProfileForm.controls[key].reset()
            this.ProfileForm.controls[key].setValue(value)
        }else{
            if(!this.ProfileForm.get(key).errors){
                let value = this.ProfileForm.controls[key].value
                this.ProfileForm.controls[key].reset()
                this.ProfileForm.controls[key].setValidators([Validators.email, Validators.required])
                this.ProfileForm.controls[key].setValue(value)
            }
        }
    }
    onUernameChange(event: string){
        if(this.ProfileForm.get('type').value == 'guest'){
            let key = 'username'
            let value = this.ProfileForm.controls[key].value
            console.log('onUernameChange', this.ProfileForm.controls[key].errors)
            if(!value && this.ProfileForm.controls[key].errors){
                this.ProfileForm.controls[key].clearValidators()
            } else if (value && !this.ProfileForm.controls[key].errors) {
                this.ProfileForm.controls[key].setValidators([Validators.email])
            }
        }
    }

    onSaveProfile(){
        var m = cloneDeep(this.ProfileForm.value)
        if (!this.ProfileForm.valid){
            for(let key in this.ProfileForm.controls){
              try{
                if(this.ProfileForm.get('type').value == 'guest' && key == 'username'){
                    if(this.ProfileForm.get('username').value){
                        this.ProfileForm.controls[key].markAsDirty()
                        this.ProfileForm.controls[key].markAsTouched()
                        this.ProfileForm.controls[key].markAsUntouched()
                    }else{
                        // console.log('continue key', key)
                        // set error to null
                        let value = this.ProfileForm.controls[key].value
                        this.ProfileForm.controls[key].reset()
                        this.ProfileForm.controls[key].setValue(value)
                        continue
                    }
                }
                if(key == 'username' && this.ProfileForm.get('type').value == 'member'){
                    if(!this.ProfileForm.get('username').errors){
                        this.ProfileForm.controls[key].setValidators([Validators.email, Validators.required])
                    }
                }
                if(!this.ProfileForm.controls[key].valid){
                  this.ProfileForm.controls[key].markAsDirty()
                  this.ProfileForm.controls[key].markAsTouched()
                  this.ProfileForm.controls[key].markAsUntouched()
                }
              }catch(err){}
            }
            if ((!m.subdistrict || !m.district) && m.country_name == 'Thailand'){
                return;
            }
            else if ((!m.subdistrict || !m.district) && m.country_name != 'Thailand'){
            }else{
                return;
            }
        }

        for(let key in this.ProfileForm.controls){
            this.ProfileForm.value[key] = this.ProfileForm.controls[key].value
        }
        if(!this.ProfileForm.value['lastname']){
            this.ProfileForm.value['lastname'] = ' '
        }
        // return
        console.log(this.countries);
        $('.theme-loader').fadeIn();
        if(this.data.id !== "new"){
            var model = cloneDeep(this.ProfileForm.value)
            model["id"] = this.data.id;
            model["first_name"] = model.name;
            model["last_name"] =  model.lastname;
            model["address"] =  model.address1;
            model["country_name"] =  model.country_name;
            model["country_alpha2_code"] = this.countries.find(x => x.country_name == model["country_name"]).country_alpha2_code;
            model["email"] =  model.username;
            this._customerSvc.update(model).then((res:any) =>{
                this.modal.close(res);
                $('.theme-loader').fadeOut();
            }).catch((err: apiError)=>{
                $('.theme-loader').fadeOut();
                console.log(err)
                this.modalService.error({
                    nzTitle: `${err.error.message}`
                })
                // alert(err.error.message)
            })

            return
        }else{
            var model = cloneDeep(this.ProfileForm.value)
            model["first_name"] = model.name;
            model["last_name"] =  model.lastname;
            model["email"] =  model.username;
            model["address"] =  model.address1;
            model["country_name"] =  model.country_name;
            model["country_alpha2_code"] = this.countries.find(x => x.country_name == model["country_name"]).country_alpha2_code;
            this._customerSvc.create(model).then((res:any) =>{
                this.modal.close(res);
                $('.theme-loader').fadeOut();
            }).catch((err: apiError)=>{
                $('.theme-loader').fadeOut();
                console.log(err)
                this.modalService.error({
                    nzTitle: `${err.error.message}`
                })
                // alert(err.error.message)
            })
            return
        }

     }

     onSearchDistrict(district: string = ""){
        console.log('onSearchDistrict', district)
        this._utilSvc.getDistrict({
            "name": district,
            "limit": 15
        }).then((res:any[]) =>{
            this.District = res
            //console.log(res)
        })
     }

     onSearchSubDistrict(subdistrict: string = ""){
        this._utilSvc.getSubDistrict({
            "name": subdistrict,
            "limit": 15
        }).then((res:any[]) =>{
            this.SubDistrict = res
            //console.log(res)
        })
     }


    handleCancel(){
        this.closeModal();
    }

    ok(){
        this.onSaveProfile();
        return false;
    }

    closeModal(e:boolean = false){
        this.modal_edit = false;
        this.event_callback.emit(e);
    }

    onCountryChange() {
        this.model.country_name = this.ProfileForm.get("country_name").value
        this.model.country_alpha2_code =  this.countries.find(x => x.country_name == this.model.country_name).country_alpha2_code
        if(this.model.country_alpha2_code == 'TH'){
            this.ProfileForm.get("subdistrict").setValue("")
            this.ProfileForm.get("district").setValue("")
            this.ProfileForm.get("province").setValue("")
            this.ProfileForm.get("zipcode").setValue("")
            if(this.address_layout == 2){
                this.ProfileForm.get('province').disable()
                this.ProfileForm.get('zipcode').disable()
            }
        }else{
            this.ProfileForm.get("subdistrict").setValue("-")
            this.ProfileForm.get("district").setValue("-")
            this.ProfileForm.get("province").setValue("")
            this.ProfileForm.get("zipcode").setValue("")
            if(this.address_layout == 2){
                this.ProfileForm.get('province').enable()
                this.ProfileForm.get('zipcode').enable()
            }
        }
    }


    addressChange(data: any) {

        let subdistrict = (data) ? data.district : "";
        let district = (data) ? data.city : "";
        let zipcode = (data) ? data.zipcode : "";
        let province = (data) ? data.province : "";
    
        this.ProfileForm.get("subdistrict").setValue(subdistrict)
        this.ProfileForm.get("district").setValue(district)
        this.ProfileForm.get("province").setValue(province)
        this.ProfileForm.get("zipcode").setValue(zipcode)

        this.onSearchSubDistrict(subdistrict);
        this.onSearchDistrict(district);
    
    }
    
}

