import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UtilService } from '@cms/services/util.service';
import { PromotionService } from '@cms/services/promotion.service';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { FeatureService } from '@cms/services/feature.service';

@Component({
  selector: 'app-modal-add-edit-discount-condition',
  templateUrl: './modal-add-edit-discount-condition.component.html',
  styleUrls: ['./modal-add-edit-discount-condition.component.css']
})
export class ModalAddEditDiscountConditionComponent implements OnInit {
  @Input() data: any;
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Promotion: PromotionService,
    private Service_Feature: FeatureService
  ) { }
  rangePicker: any = [];
  currentdatetime: any;
  message_duration: number = 4000;
  model: any = {
    max_use: 0,
    use: 0,
    is_use_total: false,
    total_type:  'value',
    status: 1,
    title: "",
    total: 0,
    freeshipping: false,
    is_use_max_discount: false,
    max_discount: 0,
    startdate: "",
    enddate: "",
    discount: 1,
    discount_type: "normal"
  }
  feature_shipping_inter: boolean = false;

  async ngOnInit() {
    this.feature_shipping_inter = await this.Service_Feature.getFeature('SHIPPING_INTER');
    if (this.data.state == 'create' && !this.data.hasOwnProperty('model')) {
      this.currentdatetime = await this.Service_Util.getDateTime();
      this.rangePicker = [moment(this.currentdatetime.date).format('YYYY-MM-DD'), moment(this.currentdatetime.date).add(1, 'month').format('YYYY-MM-DD')];
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
    } else {
      this.model = cloneDeep(this.data.model);
      this.rangePicker = [this.model.startdate, this.model.enddate];
      if (!this.model.hasOwnProperty('max_use')) this.model.max_use = 0;
      if (!this.model.hasOwnProperty('total_type')) this.model.total_type = 'value';
      if (this.data.state == 'create') {
        this.model.use = 0;
      }
    }
  }

  dateRangeChange(){
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
    } catch (e) {
      console.log(e);
      this.model.startdate = "";
      this.model.enddate = "";
    }
  }
  
  validate() {

    if (!this.model.title) {
      this.message.error('โปรดกรอกชื่อส่วนลด', { nzDuration: this.message_duration })
      return false;
    }
    else if (this.model.startdate == "" || this.model.enddate == "") {
      this.message.error('โปรดเลือก ระยะเวลาให้ถูกต้อง', { nzDuration: this.message_duration })
      return false;
    }
    else if (Number(this.model.discount) < 0) {
      this.message.error('มูลค่าต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false;
    }
    else if (Number(this.model.discount) > 100 && this.model.discount_type == 'percent') {
      this.message.error('มูลค่าต้องไม่เกิน 100 %', { nzDuration: this.message_duration })
      return false;
    }
    else if (Number(this.model.max_discount) == 0 && this.model.is_use_max_discount) {
      this.message.error('ส่วนลดสูงสุดต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false;
    }
    return true;
  }

  async ok() {
    console.log(this.model);

    if (!this.validate()) {
      return false;
    }
    try {
      if (this.data.state == 'create') {
        await this.Service_Promotion.createDiscountCondition(this.model);
        this.modal.close(true);
      } else {
        // console.log(this.model);
        await this.Service_Promotion.updateDiscountCondition(this.model);
        this.modal.close(true);
      }
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: 5000 });
    }
  }

  debug() {
    console.log('data: ', this.data)
    console.log('model: ', this.model)
  }

}
