import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { pathLangPipe } from 'src/app/pipes/path-lang.pipe';
import { Etc } from 'src/app/util/etc';
import { HttpClient } from '@angular/common/http';

declare var alasql: any;

@Component({
  selector: 'ket-thailand-address',
  templateUrl: './thailand-address.component.html',
  styleUrls: ['./thailand-address.component.scss']
})
export class KetThailandAddressComponent implements OnInit, AfterViewInit, OnDestroy {
  search: any;
  selected: any;
  isLoading:boolean = false;
  db:any = [];
  result:any = [];
  perpage:number = 10;
  page:number = 1;
  @Output() onSelected = new EventEmitter()

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected pathLang: pathLangPipe,
    protected route: Router,
    private _etc: Etc,
    private http: HttpClient
  ) { }
  
  ngOnDestroy() {
  }

  async ngOnInit() {

    // let db = this._etc.getThailandAddress();
    // if(db.length == 0){
    this.db = await this.http.get('/assets/db-thailand.json').toPromise();
    // }
    console.time("start");
    for(let i =0;i < this.db.length; i++){
      let current = this.db[i];
      let ziparr = `${current.zipcode}`.split(",");
      if (ziparr.length > 1){
        current.zipcode = ziparr[0];
        for(let j = 1; j < ziparr.length; j++){
          let obj = {
            city: current.city,
            district: current.district,
            province: current.province,
            zipcode: ziparr[j]
          }
          this.db.splice(i,0,obj);
        }
      }
    }
    console.timeEnd("start");
    // console.log(address);

  }
  ngAfterViewInit() {

  }

  _onSelected(){
    console.log(this.selected);
    this.onSelected.emit(this.selected);
  }

  async onSearch(search){
    let key_length = search.length;
    this.search = search;
    this.page = 1;
    this.result = await this.findAddress(search);
  }

  async findAddress(search){
    search = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    var offset_page = Number(this.perpage) * (Number(this.page) - 1);
    return await alasql(`SELECT * FROM ? WHERE province LIKE ? OR city LIKE ? OR district LIKE ? OR zipcode LIKE ? LIMIT ${this.perpage} OFFSET ${offset_page}`, [this.db, `%${search}%`, `%${search}%`, `%${search}%`, `%${search}%`]);
  }

  async loadMore(){
    this.isLoading = true;
    console.log('loadmore');
    this.page++;
    let rs = await this.findAddress(this.search);
    if(rs.length){
      this.result = [...this.result, ...rs];
    }
  
    this.isLoading = false;
  }
}