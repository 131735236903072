
import { Injectable } from '@angular/core';

interface PageSizeBackend {
    global: number
}

@Injectable({
    providedIn: 'root'
})
export class Etc{

    isBackEnd(){
        let search = location.href.search(`${window.location.origin}/system`);
        // console.log("isbackend", search);
        if(search == -1){
            return false;
        }else{
            return true;
        }
      }
    
    getCurrentLang(){
        let backend = this.isBackEnd();
        let lang = "lang1";
    
        if(backend){
            lang = localStorage.getItem('lang_backend');
            if(!lang){
                localStorage.setItem('lang_backend','lang1');
            }
            lang = localStorage.getItem('lang_backend');
        }else{
            lang = localStorage.getItem('lang');
            if(!lang){
                lang = 'lang1';
            }
        }
        return lang;
    }

    getPageSize(modelName: string = "global"): number {
        let pageSizeBackend = localStorage.getItem('page_size_backend');
        try {
            if (!pageSizeBackend) throw new Error("local storage page_size_backend not exist")
            let model = JSON.parse(pageSizeBackend) as PageSizeBackend
            let size = model[modelName]
            return size || 10
        } catch (err) {
            return 10
        }
    }

    setPageSize(pageSize: number = 10, modelName: string = "global") {
        let pageSizeBackend = localStorage.getItem('page_size_backend');
        try {
            if (!pageSizeBackend) throw new Error("local storage page_size_backend not exist")
            let model = JSON.parse(pageSizeBackend) as PageSizeBackend
            model[modelName] = pageSize
            localStorage.setItem('page_size_backend', JSON.stringify(model));
        } catch (err) {
            let model = {
                [modelName]: pageSize
            }
            localStorage.setItem('page_size_backend', JSON.stringify(model));
        }
        
    }

    getFilterChannel(modelName: string): string[] {
        let channelsBackend = localStorage.getItem('channels_backend');
        try {
            if (!channelsBackend) throw new Error("local storage channels_backend not exist")
            let model = JSON.parse(channelsBackend)
            let channels = model[modelName]
            return channels || []
        } catch (err) {
            return []
        }
    }

    setFilterChannel(modelName: string, channels: string[]) {
        let channelsBackend = localStorage.getItem('channels_backend');
        try {
            if (!channelsBackend) throw new Error("local storage channels_backend not exist")
            let model = JSON.parse(channelsBackend)
            model[modelName] = channels
            localStorage.setItem('channels_backend', JSON.stringify(model));
        } catch (err) {
            let model = {
                [modelName]: channels
            }
            localStorage.setItem('channels_backend', JSON.stringify(model));
        }
        
    }

    orderstatus_img() {
        return {
            '-1': { img: '/assets/images/core-imgs/icon/order-status5.png', name: 'ยกเลิก' },
            '0': { img: '/assets/images/core-imgs/icon/order-status0.png', name: 'Pending' },
            '1': { img: '/assets/images/core-imgs/icon/order-status1.png', name: 'รอชำระเงิน' },
            '2': { img: '/assets/images/core-imgs/icon/order-status2.png', name: 'ได้รับเงิน' },
            '3': { img: '/assets/images/core-imgs/icon/order-status3.png', name: 'จัดเตรียมสินค้า' },
            '4': { img: '/assets/images/core-imgs/icon/order-status4.png', name: 'จัดส่ง' },
        }
    }

    payment_img() {
        return {
            '-2': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'รอชำระเงิน' },
            '-1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
            '1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
            '2': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'โอนผ่านธนาคาร' },
            '3': { img: '/assets/images/core-imgs/icon/payment-credit.png', name: 'เครดิตการ์ด' },
            '5': { img: '/assets/images/core-imgs/icon/payment-qrcode.png', name: 'QR CODE' },
            '4': { img: '/assets/images/core-imgs/icon/payment-cod.png', name: 'เก็บเงินปลายทาง' },
            '6': { img: '/assets/images/core-imgs/icon/payment-crypto.svg', name: 'Crypto currency' },
            '7': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'อื่นๆ' }
        }
    }
}
