import { Component, OnInit, AfterViewInit, Input, ViewChildren, QueryList, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { SettingService } from "@cms/services/setting.service";
import { apiError } from "@cms/interface/interface";
import { cloneDeep, merge } from "lodash";
import { Router } from '@angular/router';
import { UtilService } from '@cms/services/util.service';
import { Etc } from '@cms/util/etc';

@Component({
  selector: "app-reder-style",
  templateUrl: "./reder-style.html",
  styleUrls: ["./reder-style.css"]
})
export class RenderStyleComponent implements OnInit, AfterViewInit {
  @Input() renderType: string;


  @ViewChildren('_genStyle_') _genStyle_: QueryList<any>

  observer:any

  options: any;
  button: any = {
    backgroundcolor: "#525e68",
    backgroundcolorhover: "#fff",
    border: "2",
    bordercolor: "#525e68",
    bordercolorhover: "#525e68",
    borderhover: "2",
    borderradius: "9",
    borderradiushover: "9",
    fontcolor: "#fff",
    fontcolorhover: "#525e68"
  };
  ecommerce: any = {
    backtotopbackgroundcolor: "#fac769",
    backtotopfontcolor: "#a66900",
    backtotophover: "#fac769",
    cartbuttoncolor: "#fac769",
    cartbuttonhover: "#fac769",
    menucarthover: "#cccccc",
    cartbuttonfontcolor: "#fff",
    cartbuttonbgfontcolor: "#000"
  };
  image_cart_model: string = "/upload-img/Theme/toy-bag.png";
  whatshelp_button: any;
  btn_back_to_top_status: boolean = false;
  btn_back_to_top_transparent: boolean = false;
  btn_cart_status: boolean = false;
  btn_cart_transparent: boolean = false;
  _theme_color: any;
  advance_css: string = "";
  header_template:string = '1'
  theme_color: any = {
    color_primary: ''
  };
  theme_color_default: any;

  reder_setting: boolean = false;
  modal_status: boolean = false;

  seo:boolean = false

  hide_lang_tab:boolean = false

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, 
  private _setting: SettingService, private _router: Router, 
  private _util: UtilService,
  private _etc: Etc
  ) {}

  ngOnInit() {
    window["_openModalColorSetting"] = () => {
      this.openModal();
    };

    if(!window['seo']){
      this.seo  = false

      this._util.getLangs().then((res:any) =>{

        console.log('getLangs', res, res.length)

        if(res.length == 1){
          this.hide_lang_tab = true
        }
      }).catch(errr =>{

      })

    }else{
      this.seo  = true
    }

  }

  ngAfterViewInit() {
    this.initColor();
  }

  initStyle(){
    try{
      var _html = this._etc.genStyleColor(this.theme_color, this, this.modal_status, this.advance_css, this.hide_lang_tab)
      this._document.getElementById('_genStyle_color').innerHTML = _html
      localStorage.setItem('_genStyle_color', _html)
    }catch{}
  }

  initColor() {
    this._setting
      .searchOption({
        pop_key: [
          "btn_back_to_top_status",
          "btn_back_to_top_transparent",
          "btn_cart_status",
          "btn_cart_transparent",
          "theme_value",
          "advance_css",
          "bag_icon",
          'header_template'
        ]
      })
      .then((res: any[]) => {
        this.options = res;
        for (let obj of res) {
          switch (obj.pop_key) {
            case "advance_css":
              this.advance_css = obj.pop_value;
              break;
            case "btn_back_to_top_status":
              this.btn_back_to_top_status =
                Number(obj.pop_value) == 1 ? true : false;
              break;
            case "btn_back_to_top_transparent":
              this.btn_back_to_top_transparent =
                Number(obj.pop_value) == 1 ? true : false;
            case "btn_cart_status":
              this.btn_cart_status = Number(obj.pop_value) == 1 ? true : false;
              break;
            case "btn_cart_transparent":
              this.btn_cart_transparent =
                Number(obj.pop_value) == 1 ? true : false;
              break;
            case "theme_value":
              this._theme_color = obj.pop_value;

              try {
                var _data = JSON.parse(obj.pop_value);
                //console.log(_data);
                window["_theme_color"] = _data;
              } catch (e) {
                eval(`${obj.pop_value} window._theme_color = _theme_color;`);
              }
              setTimeout(() => {
                try {
                  this.button = merge(
                    this.button,
                    cloneDeep(window["_theme_color"]["button"])
                  );
                  this.ecommerce = merge(
                    this.ecommerce,
                    cloneDeep(window["_theme_color"]["ecommerce"])
                  );

                  if(!window["_theme_color"]["ecommerce"].hasOwnProperty('cartbuttonfontcolor')){
                    window["_theme_color"]["ecommerce"]['cartbuttonfontcolor'] = this.ecommerce.cartbuttonfontcolor
                  }

                  if(!window["_theme_color"]["ecommerce"].hasOwnProperty('cartbuttonbgfontcolor')){
                    window["_theme_color"]["ecommerce"]['cartbuttonbgfontcolor'] = this.ecommerce.cartbuttonbgfontcolor
                  }

                  if(!window["_theme_color"].hasOwnProperty('color_primary')){
                    window["_theme_color"]['color_primary'] = this.button.backgroundcolor
                }

                  this.theme_color = window["_theme_color"];
                  this.theme_color_default = cloneDeep(this.theme_color);

                  console.log( window["_theme_color"]["ecommerce"])
                } catch (err) {}




                  this.observer = new MutationObserver(mutations => {
                    this.initStyle()
                  });
                  var config = { attributes: true, childList: true, characterData: true };

                  this.observer.observe(this._genStyle_.first.nativeElement, config);

                  this.initStyle()




              }, 100);
              break;
            case "bag_icon":
              this.image_cart_model = obj.pop_value;
              break;
            case "header_template":
              this.header_template = `${obj.pop_value}`;
              break;
            default:
              break;
          }
        }
        //console.log(res)
        setTimeout(() => {
          this.reder_setting = true;
        }, 150);
      })
      .catch((e: apiError) => {
        console.log(e.message);
      });
  }

  openModal() {
    this.theme_color = window["_theme_color"];
    this.theme_color_default = cloneDeep(this.theme_color);
    this.modal_status = true;
  }

  closeModal() {
    for (let key in this.theme_color_default) {
      this.theme_color[key] = this.theme_color_default[key];
    }
    this.modal_status = false;
    this.initColor();
  }

  genStyle() {
    try {
      var color = this.theme_color;

      var s: string = `
        <style>

            :root {
                --bg-primarycolor: ${color.color_primary}1a;
                --primarycolor: ${color.color_primary};
                --button-backgroundcolor: ${color.button.backgroundcolor};
                --button-backgroundcolorhover: ${
                  color.button.backgroundcolorhover
                };
                --button-border: ${color.button.border}px;
                --button-bordercolor: ${color.button.bordercolor};
                --button-bordercolorhover: ${color.button.bordercolorhover};
                --button-borderhover: ${color.button.borderhover}px;
                --button-borderradius: ${color.button.borderradius}px;
                --button-borderradiushover: ${color.button.borderradiushover}px;
                --button-fontcolor: ${color.button.fontcolor};
                --button-fontcolorhover: ${color.button.fontcolorhover};

                --footer-backgroundcolor: ${color.footer.backgroundcolor};
                --footer-font_hover: ${color.footer.font_hover};
                --footer-fontcolor: ${color.footer.fontcolor};

                --header-backgroundcolor: ${color.header.backgroundcolor};
                --header-backgroundcolor_logo: ${
                  color.header.backgroundcolor_logo
                };

                --menu-background_hover: ${color.menu.background_hover};
                --menu-border_hover: ${color.menu.border_hover};
                --menu-font_hover: ${color.menu.font_hover};
                --menu-fontcolor: ${color.menu.fontcolor};

                --product-backgroundcolor: ${color.product.backgroundcolor};
                --product-fontcolor: ${color.product.fontcolor};

                --socialfooter-backgroundcolor: ${
                  color.socialfooter.backgroundcolor
                };
                --socialfooter-fontcolor: ${color.socialfooter.fontcolor};

                --socialheader-backgroundcolor: ${
                  color.socialheader.backgroundcolor
                };

                --submenu-background_hover: ${color.submenu.background_hover};
                --submenu-font_hover: ${color.submenu.font_hover};

                --topbar-backgroundcolor: ${color.topbar.backgroundcolor};
                --topbar-font_hover: ${color.topbar.font_hover};
                --topbar-fontcolor: ${color.topbar.fontcolor};

            }

            .nav-mobile .nav-link{
              color:  ${color.menu.fontcolor}!important;
            }


            .tmt-btn-all{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all:hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .tmt-btn-all:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .btn-outline-tmt{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .btn-outline-tmt:hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .btn-outline-tmt:focus{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .btn-outline-tmt.hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .fr-view .tmt-btn-all{
              background-color: ${color.button.backgroundcolor}!important;
              color:  ${color.button.fontcolor}!important;
              border: solid  ${color.button.border}px!important;
              border-color:  ${color.button.bordercolor}!important;
              border-radius: ${color.button.borderradius}px!important;
            }

            .fr-view .tmt-btn-all:hover,
            .fr-view .tmt-btn-all:focus,
            .fr-view .tmt-btn-all.hover{
              background-color: ${this.button.backgroundcolorhover}!important;
              color:  ${this.button.fontcolorhover}!important;
              border: solid  ${this.button.borderhover}px!important;
              border-color:  ${this.button.bordercolorhover}!important;
              border-radius: ${this.button.borderradiushover}px!important;
            }

            .fr-view .tmt-btn-all:hover span,
            .fr-view .tmt-btn-all:focus span{
              color:  ${this.button.fontcolorhover}!important;
            }



            .color_render_wrap .ant-btn-primary{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-primary:hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }
            .color_render_wrap .ant-btn-default{
              border: solid  ${color.button.border}px;
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-default:hover{
              border: solid  ${this.button.borderhover}px;
              border-radius: ${this.button.borderradiushover}px;
            }

            .color_render_wrap .ant-btn-primary:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .color_render_wrap .ant-btn-primary.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .ant-pagination-item-active,
            .ant-pagination-item:focus,
            .ant-pagination-item:hover {
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus,
            .ant-pagination-item-active:hover{
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus a,
            .ant-pagination-item-active:hover a,
            .ant-pagination-item:focus a,
            .ant-pagination-item:hover a{
              color:  ${color.button.fontcolor};
            }

            .btn_search_color{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            .btn_search_color:hover{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            app-page-product04 .ant-slider-handle,
            app-page-product04 .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
              border-color:  ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider:hover .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }


            #wh-widget-send-button {
              z-index: 99!important;
            }


            .static-bag{
                ${this.btn_cart_status ? "display: inline-block!important;" : "display: none!important;"}
                ${
                  this.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttoncolor};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-bag:hover{
                ${
                  this.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttonhover};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-top{
                ${this.btn_back_to_top_status ? "display: inline-block!important;" : "display: none!important;"}
                ${
                  this.btn_back_to_top_transparent
                    ? `background-color: ${
                        color.ecommerce.backtotopbackgroundcolor
                      };`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }
            .static-top i{
                color: ${color.ecommerce.backtotopfontcolor};
            }
            .static-top:hover{
                ${
                  this.btn_back_to_top_transparent
                    ? `background-color: ${color.ecommerce.backtotophover};`
                    : "background-color: rgba(250, 199, 105, 0);"
                }
            }


            .item-example,
            .nav-item-example{
              display: ${this.modal_status? 'block' : 'none'}!important;
            }
            .nav-item-show{
              display: ${this.modal_status? 'none' : 'block'}!important;
            }

            .static-bag .number-on-bag-float{
              background-color: ${color.ecommerce.cartbuttonbgfontcolor};
              color: ${color.ecommerce.cartbuttonfontcolor};
            }

            ${this.advance_css}

            .header_mainmenu.sticky{
              background-color: var(--header-backgroundcolor)!important;
              box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
          }

          ${this.hide_lang_tab? '.header_language{     display: none!important; }' : '' }

        </style>
    `;

      s = s.replace(/(\r\n|\n|\r)/gm, "");
      s = s.replace(/        /g, '');

      return s;
    } catch (err) {
      //console.log(err.message)
      return "";
    }
  }

  saveColor() {
    for (let obj of this.options) {
      switch (obj.pop_key) {
        case "bag_icon":
          obj.pop_value = this.image_cart_model;
          break;
        case "btn_back_to_top_status":
          obj.pop_value = this.btn_back_to_top_status ? "1" : "0";
          break;
        case "btn_back_to_top_transparent":
          obj.pop_value = this.btn_back_to_top_transparent ? "1" : "0";
          //console.log(obj.pop_value);
          break;
        case "btn_cart_status":
          obj.pop_value = this.btn_cart_status ? "1" : "0";
          break;
        case "btn_cart_transparent":
          obj.pop_value = this.btn_cart_transparent ? "1" : "0";
          break;
        case "theme_value":
          obj.pop_value = JSON.stringify(this.theme_color);
          break;
        default:
          break;
      }
    }
    $(".theme-loader").fadeIn();
    this._setting
      .saveOption(this.options)
      .then((res: any) => {
        var url = decodeURI(this._router.url);
        this._router.navigated = false;
        this._router
          .navigate([`/system`])
          .then(() => this._router.navigate([url]));
      })
      .catch((e: apiError) => {
        console.log(e.error.message);
      })
      .finally(() => {
        $(".theme-loader").fadeOut();
      });
  }
}
