import {
    Directive,
    ElementRef,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
  } from '@angular/core';
  
  @Directive({
    selector: '[responsive-file]'
  })
  export class KetResponsiveFileDirective implements AfterViewInit, OnChanges{
  
    constructor(private el: ElementRef) {}
  
    @Output() image = new EventEmitter();
    @Input('responsive-file') responsive_file:string;
  
    ngAfterViewInit(){
  
      this.initFile()
  
    }
  
  
    initFile(){
  
      window['responsive_filemanager_callback'][this.responsive_file] =  (field_id:any,infoImg:any) => {
  
        if(infoImg == 'close'){
  
          var overlay = $('.vbox-overlay');
          var obj = $('.iframe-media');
          $('body').removeClass('vbox-open');
          overlay.animate({ opacity: 0 }, 500, function () {
            overlay.remove();
          });
          return;
        }
        //console.log(infoImg);
        //var chkUrl:any = $('#' + field_id).val();
        this.image.emit(infoImg);
  
        //console.log(this.responsive_file);
  
        var overlay = $('.vbox-overlay');
        var obj = $('.iframe-media');
        $('body').removeClass('vbox-open');
        overlay.animate({ opacity: 0 }, 500, function () {
          overlay.remove();
        });
      }
  
    }
  
    ngOnChanges(changes: SimpleChanges){
      if(changes.responsive_file){
        if(changes.responsive_file.previousValue !== undefined){
          this.responsive_file = changes.responsive_file.currentValue;
          this.initFile()
        }
      }
    }
  
  
  }
  