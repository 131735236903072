
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { AuthenService } from './services/authen.service';
import { switchMap, filter, take } from 'rxjs/internal/operators';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Etc } from './util/etc';
import { Router } from '@angular/router';
import { SET_CURRENT_USER_CLIENT } from './store/action';
import { Store } from '@ngrx/store';
import * as jwt_decode from "jwt-decode";


@Injectable()
export class APIInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(
      public auth: AuthenService,
      private router: Router,
      private store: Store<{ app_store: any }>,
      private _etc: Etc) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

      var header:any = {};

      try {
        var _0x38df = [
            'X19yZWZfXw==',
            'getItem'
        ];
        (function (_0x26c545, _0x38df73) {
            var _0x24475e = function (_0x110730) {
                while (--_0x110730) {
                    _0x26c545['push'](_0x26c545['shift']());
                }
            };
            _0x24475e(++_0x38df73);
        }(_0x38df, 0x106));
        var _0x2447 = function (_0x26c545, _0x38df73?) {
            _0x26c545 = _0x26c545 - 0x0;
            var _0x24475e = _0x38df[_0x26c545];
            return _0x24475e;
        };
        var _0x3096 = [
            _0x2447('0x1'),
            atob(_0x2447('0x0')),
            atob('eC1kZXZpY2UtcmVm')
        ];
        (function (_0x5d0010, _0x3096e2) {
            var _0x38803b = function (_0x3df62a) {
                while (--_0x3df62a) {
                    _0x5d0010['push'](_0x5d0010['shift']());
                }
            };
            _0x38803b(++_0x3096e2);
        }(_0x3096, 0x1eb));
        var _0x3880 = function (_0x5d0010, _0x3096e2?) {
            _0x5d0010 = _0x5d0010 - 0x0;
            var _0x38803b = _0x3096[_0x5d0010];
            return _0x38803b;
        };
        header[_0x3880('0x0')] = Function[_0x3880('0x2')]();
        header['x-device-uuid'] = localStorage[_0x3880('0x1')]('device_uuid');
      } catch (err) { }

      try{
        let currentlang = this._etc.getCurrentLang();
        header['lang']=currentlang
      }catch(err){}


      try{
        header['cms-path']= location.href
      }catch(err){}

      var check:string = request.url.slice(0,8);
      //console.log(check,req.url.slice(0,4));
      if(`${request.url}`.search(/(.*).(.*)\.com/) !== -1){
        //console.log('assets API')
        var _req = request.clone({
          url: `${request.url}`
        });

        return next.handle(_req);
      }

      if(check == "/assets/" || request.url.slice(0,4) == 'http'){

        var domain = ""
        if(!window["_host"]){
          domain = window.location.origin
        }else{
          console.log('assets')
          domain = `https://${window["_host"]}`
        }
        var i18n = request.clone({
          url: `${domain}${request.url}`,
          setHeaders: header
        });
        return next.handle(i18n);
      }

        var isToken = "";
        try {
            isToken = this.auth.getAuthenToken()
            if (isToken !== "") {
                header["Authorization"] = `Bearer ${isToken}`;
            }
            try{
              var _user = jwt_decode(isToken)
              header["userid"] = 'UserID='+_user.id;
            }catch(err){}
        }catch(err){}

        let api_center = `${request.url}`.replace('/center/', `${environment.api_center}/v1/`)
        let api_url = `${environment.api_path}${request.url}`
        if(`${request.url}`.substring(0,10) == '/services/'){
          api_url = api_url.replace("/api/v1/","/")
          api_url = `${environment.ketshopweb_services}${request.url}`
        }
        if(`${request.url}`.substring(0,8) == '/center/'){
          api_url = api_center
        }
        const apiReq = request.clone({
            // url: `${request.url}`.substr(0,8) == '/center/'?api_center:`${environment.api_path}${request.url}`,
            url: api_url,
            setHeaders: header
        });

        return next.handle(apiReq).pipe(catchError((error: HttpErrorResponse) =>{

          if (apiReq.url.includes("/login/token")) {
            this.signOut()
            return throwError(error);
          }

          if (error.status !== 401) {
            return throwError(error);
          }

          if(!this.auth.getRefreshToken()){
            return throwError(error);
          }

          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(()=> next.handle(this.addAuthenticationToken(apiReq)))
            )
          }else{
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.auth.refresh(this.auth.getRefreshToken()).pipe(switchMap((token:any)=>{
                localStorage.setItem(this.auth.getKeyName(), JSON.stringify(token))
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(token);
                return next.handle(this.addAuthenticationToken(apiReq));
            }), catchError((err: any) => {
                this.refreshTokenInProgress = false;
                this.signOut()
                return throwError(error);
            }))
          }

        }))
    }

    addAuthenticationToken(request_add) {
        const accessToken = this.auth.getAuthenToken();
        if (!accessToken) {
            return request_add;
        }
        return request_add.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getAuthenToken()}`
            }
        });
    }

    signOut(){
      var url = window.location.href
      if(url.search(`${window.location.origin}/system`) == -1){
        localStorage.removeItem(this.auth.TOKEN_CLIENT_NAME)
        this.store.dispatch( new SET_CURRENT_USER_CLIENT({}) )
        this.router.navigate([decodeURI(`/`)]);
      }else{
        localStorage.removeItem(this.auth.TOKEN_NAME)
        this.router.navigate(['/system/login']);
      }
    }
}


/*
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { cloneDeep } from 'lodash';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { switchMap } from 'rxjs/internal/operators';
//import { AuthenService } from "./services/authen.service";

import { environment } from './../environments/environment';
import { AuthenService } from './services/authen.service';
import { Router } from '@angular/router';
import { Etc } from './util/etc';
import * as jwt_decode from "jwt-decode";


@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(
    private _authen: AuthenService,
    private router: Router,
    private _etc: Etc
  ) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>>{

    var header:any;
    let contentType = req.headers.get('content-type') || "application/json";

    if(!window["seo"]){
      let currentlang = this._etc.getCurrentLang();
      header = {
        // "Content-Type": contentType,
        "lang": currentlang
      };
      // localStorage.setItem('lang', localStorage.getItem('lang') || "lang1")
      this._etc.setCurrentLang( currentlang );
    }else{
      //console.log(window["hostname"])
      var lang = this._etc.getCurrentLang();
      //console.log(lang)
      if(!lang){
        lang = "lang1"
      }
      header = {
        // "Content-Type": contentType,
        "lang": lang,
        "referer-server": window["hostnameJWT"]
      };

    //  localStorage.setItem('lang', localStorage.getItem('lang') || "lang1")

    }

    //console.log(header)



    //console.log(req.headers.get('content-type'));



    var check:string = req.url.slice(0,8);
    //console.log(check,req.url.slice(0,4));
    if(`${req.url}`.search(/(.*).(.*).com/) !== -1){
      //console.log('assets API')
      var _req = req.clone({
        url: `${req.url}`
      });

      return next.handle(_req);
    }

    if(check == "/assets/" || req.url.slice(0,4) == 'http'){
      //console.log('assets API')
      var domain = ""
      if(!window["_host"]){
        domain = window.location.origin
      }else{
        domain = `https://${window["_host"]}`
      }
      var i18n = req.clone({
        url: `${domain}${req.url}`,
        setHeaders: header
      });
      return next.handle(i18n);
    }

    var isToken = "";
    try{
      var _data = cloneDeep(this._authen.getToken());
      isToken = _data.access_token
      //console.log(isToken)
    }catch{}



    if(this._authen.isTokenExpired() && isToken !== "" && `${req.url}`.search('/login/token') == -1){
     // console.log('JWT Expired', req.url, this._authen.isTokenExpired(),isToken);
      //console.log(_data)
      var refresh_token:any = cloneDeep(_data);
      this._authen.removeToken();

      return this._authen.refresh(refresh_token.refresh_token).pipe(switchMap((res:any)=>{
        // alert(555555);
        this._authen.setToken(res);
        try{
          header["Authorization"] = `Bearer ${res.access_token}`;
          try{
            var _user = jwt_decode(isToken)
            header["userid"] = 'UserID='+_user.id;
          }catch(err){}
        }catch{}
        var clone_state_api = req.clone({
          url: `${environment.api_path}${req.url}`,
          setHeaders: header
        });

        return next.handle(clone_state_api);

      }),catchError((error: HttpErrorResponse) => {

        ///console.log(JSON.stringify(error));

        if (error.status == 401) {
            var url = window.location.href
            if(url.search(`${window.location.origin}/system`) == -1){

            }else{
                this.router.navigate(['/system/login']);
            }

          return throwError(error);

        }

        return throwError(error);
    }));

    }else{
      //console.log('JWT');
      try{
        //var access_token:any =  this._authen.getToken();
        if(isToken !== ""){
          header["Authorization"] = `Bearer ${isToken}`;
          try{
            var _user = jwt_decode(isToken)
            header["userid"] = 'UserID='+_user.id;
          }catch(err){}
        }
      }catch{
      }


      const apiReq = req.clone({
        url: `${environment.api_path}${req.url}`,
        setHeaders: header
      });

      return next.handle(apiReq).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

            }

            return event;
        }),
        catchError((error: HttpErrorResponse) => {

          ///console.log(JSON.stringify(error));

          if(error.status == 401 ){

            var url = window.location.href
            if(url.search(`${window.location.origin}/system`) == -1){

            }else{
                this.router.navigate(['/system/login']);
            }

            return throwError(error);
          }
          // return null;
          return throwError(error);
      }));


    }


  }
}

*/
