import { Component, OnInit,Input } from '@angular/core';
import { UtilService } from '@cms/services/util.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthenService } from '@cms/services/authen.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as XLSX from 'xlsx';
import { OrderService } from '@cms/services/order.service';
@Component({
  selector: 'app-modal-export-order',
  templateUrl: './modal-export-order.component.html',
  styleUrls: ['./modal-export-order.component.css']
})
export class ModalExportOrderComponent implements OnInit {
  @Input() data:any;
  currentdatetime: any;
  rangePicker: any = [];
  access_token: string;
  constructor(
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private _authen: AuthenService,
    private modal:NzModalService
  ) { }

  model:any = {
    startdate: "",
    enddate: "",
    status: "",
    paymenttype: "",
    type: 1
  }
  api_path:string;
  sunfordshop = false
  async ngOnInit() {
    // ชั่วคราว
    if(location.origin.includes('sunfordshop.com')){
      this.sunfordshop = true
    }
    
    this.api_path = environment.api_path;
    this.access_token = this._authen.getToken().access_token;
    this.currentdatetime = await this.Service_Util.getDateTime();
    this.rangePicker = [moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss'), moment(this.currentdatetime.date).endOf('day').format("YYYY-MM-DD HH:mm:ss")];
    this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
    this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
    if (this.data.created_by){
      this.model.created_by = this.data.created_by;
    }
    console.log(this.rangePicker);
  }


  exportMarketplaceDiscount(){
    console.log('exportMarketplaceDiscount');
    $('.theme-loader').show();
    this.Service_Order.exportMarketplaceDiscount(this.model).then((res:any)=>{
      $('.theme-loader').hide();
      let summary = res.summary;
      let details = res.details;
      try {
        let wb = XLSX.utils.book_new();
        wb.Props = {
          Title: 'สรุปส่วนลด Marketplace',
          CreatedDate: new Date()
        };
        wb.SheetNames.push('สรุป');
        wb.SheetNames.push('รายละเอียด');
        let summary_sheet = [];
        let detail_sheet = [];
        for(let o of summary){
          let model = {
            'Channel' : o.channel,
            'ชื่อร้าน': o.channel_name,
            'มูลค่าส่วนลด Marketplace': o.total
          }
          summary_sheet.push(model);
        }
        for(let o of details){
          let model = {
            'Channel' : o.channel,
            'ชื่อร้าน': o.channel_name,
            'มูลค่าส่วนลด Marketplace': o.price,
            'Ketshop Order': o.ordercode,
            'Marketplace Order': o.channel_ordercode,
            'วันที่เกิดออเดอร์': moment(o.order_date).format("YYYY-MM-DD HH:mm:ss")
          }
          detail_sheet.push(model);
        }
        var ws = XLSX.utils.json_to_sheet(summary_sheet);
        wb.Sheets['สรุป'] = ws;
        var ws2 = XLSX.utils.json_to_sheet(detail_sheet);
        wb.Sheets['รายละเอียด'] = ws2;

        let _s_date = moment(this.model.startdate).format("YYYYMMDD");
        let _e_date = moment(this.model.enddate).format("YYYYMMDD");

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
        let filePath = 'data:application/octet-stream;base64,' + wbout;
        let a = document.createElement('a') as any;
        a.href = filePath;
        a.download = `Marketplace-Discount-Report-${_s_date}-${_e_date}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

      }catch (err) {
        console.log(err);
        this.modal.error({
          nzContent: 'ไม่สามารถดาว์โหลดไฟล์ได้'
        })
      }

      console.log(res);
    }).catch((data:any)=>{
      $('.theme-loader').hide();
      console.log(data);
    });
  }


  dateRangeChange(){
    console.log(this.rangePicker);
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD HH:mm:ss');
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD HH:mm:ss');
    } catch (e) {
      this.model.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss');
      this.model.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss');
    }
    console.log(this.model);
  }

  

  ok(){
    if(this.model.type == 'marketplace_discount' ) {
      this.exportMarketplaceDiscount();
      return false;
    }else{
      $("form#order-export").submit();
    }
   
  }

}
